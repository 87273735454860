import React, { useEffect }  from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as s from "./styles/globalStyles";
import Footer from './Footer';
import './styles/content.css';

const bg = './bg/wide_bg_dark3.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const InfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
      <Content>
      <s.SpacerMedium />
      <h2 className={"header"}>1. Download the Rules</h2>
      <ul>
      <li>Download the rules to Eigendark here: <a href="https://drive.google.com/uc?export=open&id=1URW_7IgPPyDLnYM-0H6uROweUHfx6IqG" style={{ color: '#DAA520' }}>Rules.pdf</a></li>
      </ul>
      <s.SpacerMedium />
      <h2 className={"header"}>2. Get the cards</h2>
      <ul>
      {/*<li>I. Choose your&nbsp;<Link to="/factions" style={{ color: '#DAA520' }}>Science</Link></li>*/}
      <li>I. Get your&nbsp;<Link to="/core" style={{ color: '#DAA520' }}>Starter Deck</Link></li>
      <li>II. Buy from the&nbsp;<Link to="/gallery" style={{ color: '#DAA520' }}>Community</Link></li>
      <li>III. Subscribe to the&nbsp;<Link to="/subscribe" style={{ color: '#DAA520' }}>Zodiacs</Link></li>
      <li>IV. Copy a public&nbsp;<Link to="/decklists" style={{ color: '#DAA520' }}>Decklist</Link></li>
      <li>V. Create&nbsp;<Link to="/create" style={{ color: '#DAA520' }}>Custom Cards!</Link></li>
      </ul>
      {/*<p>IV. Buy booster packs from the&nbsp;<a href="https://www.ebay.com/itm/134861629582" target="_blank" rel="noopener noreferrer" style={{ color: '#DAA520' }}>Ethereum Set</a></p>*/}
      {/* <s.SpacerSmall />
      <p>How to mint NFTs:</p>
      <ul> 1: Get at least 11 MATIC (ca. $5 USD, via <a href="https://coinbase.com" target="_blank">Coinbase</a>, <a href="https://binance.com" target="_blank">Binance</a> or other exchange)</ul>
      <ul> 2: <a href="https://metamask.io/" target="_blank">Install</a> Metamask</ul>
      <ul> 3: <a href="https://www.coingecko.com/learn/how-to-add-polygon-network-to-metamask" target="_blank">Add</a> Polygon network</ul>
      <ul> 4: Send the MATIC to your Metamask wallet address</ul>
      <ul> 5: Click "Connect" and make sure your network is set to Polygon</ul>
      <s.SpacerSmall />*/}
      <s.SpacerMedium />
      <h2 className={"header"}>3. Play the Format</h2>
      <p>Decks have 34+ singleton cards (unique set + number). Core Set is always legal.</p>
      <ul>
      <li><strong>1. Vanilla:</strong> only cards from the same Science.</li>
      <li><strong>2. Legacy:</strong> only cards from a single Author.</li>
      <li><strong>3. Zodiac:</strong> only cards from the same Zodiac.</li>
      <li><strong>4. Chronos:</strong> only cards from any one Year.</li>
      <li><strong>5. Powerscaler:</strong> everything goes.</li>
      </ul>
      <s.SpacerMedium />
      {/* <h2 className={"header"}>4. Make your own!</h2>
      <p>Create&nbsp;<Link to="/create" style={{ color: '#DAA520' }} >custom cards</Link></p>
      {/*<p>II. Mint&nbsp;<Link to="/eth" style={{ color: '#DAA520' }}>digital cards</Link></p>
       <p>III. Craft&nbsp;<Link to="/craft" style={{ color: '#DAA520' }}>new cards</Link></p>*/}
      <h2 className={"header"}>4. Try the Demo</h2>
      <ul>
      <li>A. Try in Browser:&nbsp;<a href="https://tabletopia.com/games/eigendark-pen8hm/play-now" target="_blank" rel="noopener noreferrer" style={{ color: '#DAA520' }}>here</a>.</li>
      <li>B. Try on Steam:&nbsp;<a href="https://steamcommunity.com/sharedfiles/filedetails/?id=3142011637" target="_blank" rel="noopener noreferrer" style={{ color: '#DAA520' }}>here</a>.</li>
      </ul>
      </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default InfoPage;
