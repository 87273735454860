import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_li.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  margin-right: 15px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Life = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/life/life_faction0.jpg`} alt="Life Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/life/life_faction0.jpg`} alt="Life Magic" />
        <div>
          <h2 className={"header"}>Life Magic ☣️</h2>
          <p>In the Life Code's vision of an ideal universe, life proliferates in all its forms, celebrating the tenacity and flexibility of evolution. Their dream is a world of eternal growth and cyclical rebirth, where the boundaries between life and death are blurred, and the creation and resurrection of life are as regular as the turning of seasons. These bioengineers of life use their deep understanding of life and death to spawn new lifeforms, create diverse ecosystems, and resurrect life where it's been extinguished.
            The Life Mage's synergistic playstyle is mid- to late-game oriented, yet with the potential for explosive early turns. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/life/life_faction1.jpg`} alt="Life Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/life/life_faction1.jpg`} alt="Life Magic" />
        <div>
          <p>
          Garbed in living carapaces, intricate armors that resemble eerie, breathing, biomechanical structures, Life Mages stand as guardians of life's threshold. Their AI constructs, serpentine manifestations of raw vitality, serve to heal and enhance their masters. 
          Many are doctors, biologists, genetic engineers and even rangers, drawn to the Life Code due to their innate connection with life's cycles and deep respect for nature.
          Life Mages are the champions of this timeless cycle. Their strength lies in their ability to bend the laws of life and death, to manipulate vitality for regeneration, and master the macabre art of necromancy for resurrection. For them, the barrier between life and death is a mere illusion waiting to be dismantled. Facing a Life Mage in battle is an exhausting trial against an unyielding and ever-adapting entity, their resilience mirroring the unstoppable flow of life itself. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/life/life_faction2.jpg`} alt="Life Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/life/life_faction2.jpg`} alt="Life Magic" />
        <div>
          <p>
          Life Mages don't adhere to any conventional moral code; they are the true connoisseurs of life's full spectrum. To them, the living and the dead are mere tools, different sides of the same coin. Evolution is their playground, and Necromancy a morbidly fascinating instrument in their arsenal. This irreverence towards death and adeptness with life's myriad forces makes them formidable opponents.
          <br />
          The Life Deck is the ideal choice for those who appreciate resilience, resourcefulness and the macabre. The Life deck users can adapt and thrive in various situations, regenerating health and reviving cards from the discard pile. Plus, the deck's vibrant, unique artistry, brimming with an array of creatures and natural life, promises to captivate those with a keen interest in biology and evolution. Whether you seek to harness the power of life or to revel in the cycle of rebirth and growth, the Life deck awaits you.
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/create" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Life;

