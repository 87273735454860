import React, { useState, useEffect }  from 'react';
import styled,{ keyframes }  from 'styled-components';
import Footer from './Footer';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import ImageDisplay from './ImageDisplay'; 
import { db, auth } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Factions from './Factions';

const bg = '/bg/bg.png';
const bg2 = '/bg/bgy.png';

const POLL_INTERVAL = 10000;  // Poll every 10 seconds

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 115vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; // Changed from flex-end
`;

const Background2 = styled.div`
  background-image: url(${bg2});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding-top: 2vh; 

`;


const IntriguingTextContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.66); 
  padding: 20px;
  border-radius: 8px; 
  max-width: 750px;
  width: 80%; 
  @media (min-width: 1025px) {
    margin-left: 2.5%;
  }
  @media (max-width: 1024px) {
  margin-left: auto; 
  margin-right: auto; /* This will center the container on desktop view */
  margin-bottom: 5%;
}
`;

const IntriguingText = styled.h2`
  color: #ffffff; 
  text-align: left;
  font-size: 24px;
  margin: 0; /* Reset the default h2 margin */
`;

const BoldText = styled.span`
  font-weight: bold;
  font-size: 24px; /* Adjust the font size for the bold text */
  color: var(--secondary)
  `;

const NormalText = styled.span`
  font-weight: normal;
  font-size: 18px; /* Adjust the font size for the normal text */
`;



const pulseGlow = keyframes`
  0% {
    box-shadow: 0 0 5px gold;
  }
  50% {
    box-shadow: 0 0 20px gold, 0 0 30px gold;
  }
  100% {
    box-shadow: 0 0 5px gold;
  }
`;

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Button = styled.button`
  background: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  background-size: 200% 200%;
  border: 2px solid gold;
  animation: ${pulseGlow} 2s infinite ease-in-out, ${gradientShift} 5s ease infinite;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px gold;
  }
`;

const SloganText = styled.h1`
  font-size: 2em; 
  color: white;
  font-family: 'slogan', Arial, sans-serif; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0; // Remove any default margins
  padding: 10px 10px; // Add some horizontal padding if needed


  @media (max-width: 768px) {
    display: none;
    font-size: 1em; 
    padding: 0px 10px; // Add some horizontal padding if needed
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    color: var(--secondary);
  }
`;

const SloganTextMobile = styled.h1`
  font-size: 2em; 
  color: white;
  font-family: 'slogan', Arial, sans-serif; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0; // Remove any default margins
  padding: 10px 10px; // Add some horizontal padding if needed

  @media (min-width: 768px) {
    display: none;
    font-size: 1em; 
    padding: 0px 10px; // Add some horizontal padding if needed
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    color: var(--secondary);
  }
`;

const DashboardContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;
  max-width: 750px;
  width: 90%;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const DashboardItem = styled.div`
  color: #ffffff;
  text-align: center;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; // Ensure it takes full height of its container
`;

const DashboardValue = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: var(--secondary);
`;

const DashboardLabel = styled.div`
  font-size: 16px;
  margin-top: 5px;
`;

const fetchDashboardData = async () => {
  const dashboardDoc = doc(db, 'statistics', 'dashboard');
  const snapshot = await getDoc(dashboardDoc);
  if (snapshot.exists()) {
    console.log("snapshot");
    console.log(snapshot.data());
    return snapshot.data();
  } else {
    console.error("No dashboard statistics found");
    return { userCount: 55, totalCreations: 1385 };
  }
};

const LandingPage = ({ isLogged, handleLogin, userName, user }) => {

  const [userCount, setUserCount] = useState(0);
  const [totalCreations, setTotalCreations] = useState(0);
  const [avgCreationsPerUser, setAvgCreationsPerUser] = useState(0);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState(null); 
  const handleSubmit = async () => {
    setIsLoading(true);
    submitJobAndCheckStatus();
  };
  
  const submitJobAndCheckStatus = async (card_ai) => {
    try {
      setIsLoading(true);
      const responseFromFlask = await fetch('/api/submit-job', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ responseText: JSON.stringify(card_ai) }),
      });
  
      if (responseFromFlask.ok) {
        const { jobId } = await responseFromFlask.json();
        setJobId(jobId);
  
        // Start checking job status
        checkJobStatus(jobId);
      } else {
        console.error(`Job submission failed: ${responseFromFlask.statusText}`);
      }
    } catch (error) {
      console.error('Fetching failed', error);
    }
  };
  
  const checkJobStatus = async (jobId) => {
    //console.log(`/api/job-status?jobId=${jobId}`)
    try {
      const statusResponse = await fetch(`/api/job-status?jobId=${jobId}`);
      if (!statusResponse.ok) {
          console.error('Server response:', await statusResponse.text());
          throw new Error('Server response was not ok');
      }
      const data = await statusResponse.json();
      if (data.status === 'completed') {
        setImageSrc(data.result);
        setIsLoading(false);
      } else {
        setTimeout(() => checkJobStatus(jobId), POLL_INTERVAL);
      }
    } catch (error) {
      console.error('Status checking failed', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData();
      } else {
        setUserCount(55);
        setTotalCreations(1385);
        setAvgCreationsPerUser(0);
        setError(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchData = async () => {
    try {
      const { userCount, totalCreations } = await fetchDashboardData();
      setUserCount(userCount);
      setTotalCreations(totalCreations);
      setAvgCreationsPerUser(userCount > 0 ? (totalCreations / userCount).toFixed(0) : 0);
      setError(null);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Unable to fetch data. Please try again later.");
    }
  };


  return (
    <>
      <Background>
        <div style={{ width: '100%', marginTop: '20px' }}>
          <DashboardContainer>
            <DashboardItem>
              <DashboardValue>{userCount}</DashboardValue>
              <DashboardLabel>Creators</DashboardLabel>
            </DashboardItem>
            <DashboardItem>
            <SloganText>
              Join the Open Card Game
            </SloganText>
          </DashboardItem>
            <DashboardItem>
              <DashboardValue>{totalCreations}</DashboardValue>
              <DashboardLabel>Cards made</DashboardLabel>
            </DashboardItem>
            <DashboardItem>
            <SloganTextMobile>
              Join the Open Card Game
            </SloganTextMobile>
            </DashboardItem>
          </DashboardContainer>

        </div>

        <div className="center-container">
          {isLoading ? (
            <>
              <div className="spinner" />
            </>
          ) : (
            <ImageDisplay imageSrc={imageSrc} isLogged={isLogged} handleLogin={handleLogin} jobId={jobId} userName={userName} user={user} />
          )}
          {isLoading ? ( 
            <Button>💫 Summoning Latent Space ...</Button>
          ) : (
            <Button onClick={handleSubmit}>🪄✨ Summon</Button>
          )}
        </div>
      </Background>
      <Background2>
      <IntriguingTextContainer>
        <IntriguingText>
        <SloganText>The Eigendark OCG</SloganText>
          <br />
          <BoldText>🤗 Open Card Game</BoldText> <br />
          <NormalText>Create, collect & compete in the most open card game ever made</NormalText> <br />
          <br />
          <BoldText>⏱️ 24/7 Metagame</BoldText> <br />
          <NormalText>Make and break the meta with your own ideas</NormalText> <br />
          <br />
          <BoldText>🎨 Print on demand</BoldText> <br />
          <NormalText>Get the cards you want anywhere anytime</NormalText> <br />
          <br />
          <BoldText>👨‍👩‍👧‍👦 Big Multiplayer</BoldText> <br />
          <NormalText>Tired of waiting for turns? This game goes fast even with 4+ players</NormalText> <br />
          <br />
          <BoldText>👩‍🔬 Science System</BoldText> <br />
          <NormalText>Wield the power of dark science (totally not magic!)</NormalText> <br />
          <br />
          <BoldText>🗺️ Open World</BoldText> <br />
          <NormalText>Embed SCP and public domain works or your own fantasy creations!</NormalText> <br />
          {/*<br />
          <BoldText>🔮 No inks, no lands</BoldText> <br />
          <NormalText>This resource system brings the best of Magic's Land Mechanics without the Manascrew</NormalText> <br />
          <br />
          <BoldText>⭐ PLAYERS FIRST</BoldText> <br />
          <NormalText>No crazy rarities and super collectibles, get any card you like</NormalText> <br />*/}
        </IntriguingText>
      </IntriguingTextContainer>
      </Background2>
      <Factions />
      {/*
    <Background3>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <LazyLoad>
        <Link to="/glitch" ><StyledLogo src={`${process.env.PUBLIC_URL}/glitch/glitch_faction.jpg`} alt="Glitch Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/glitch" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/glitch/glitch_faction.jpg`} alt="Glitch Magic" /></Link>
        </LazyLoad>
        <div>
          <h2 className={"header"}>🤖 G̵͍͐ḻ̷̍i̸̻͒t̴͇̎c̶̞̔h̵̹̔ ̸̤̏M̵̯̀a̷͙͆g̸̫̔i̵̤̓c̵̟̽: Exploit the Flaws of Reality</h2>
          <p>Enter the world of politics and turbo-capitalism. Endless hierarchies of managers upon infinite back-office in the kafkaesque maze of despair!
            We fund eldritch VCs to build Dyson-Spheres to maximize the GDP of our Shoe-Empire, yes the universe needs more shoes - and feet-people that buy them!
            Exploit the bugs that lurk within the fabric of existence. Find the backdoors out of this world, enter realms you where never meant to see.
            Life's but a game, and its rules are meant to be broken.&nbsp; 
            <Link to="/glitch" >
            Sign
            </Link>
            &nbsp;this contract and be welcomed to the Company.</p>
        </div>
      </FactionContainer>
      <LazyLoad>
        <Link to="/gravity" ><StyledLogo src={`${process.env.PUBLIC_URL}/gravity/gravity_faction.jpg`} alt="Gravity Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/gravity" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/gravity/gravity_faction.jpg`} alt="Gravity Magic" /></Link>
        </LazyLoad>
        <div>
        <h2 className={"header"}>🪐 Gravity Magic: Embrace the Inevitable</h2>
        <p>Welcome, Gravimancer! The laws of physics that we regard as sacred and immutable, are anything but. To the Gravity Adept, space and time are foldable dough. 
          Bend the very fabric of space-time to your will. Like a black-hole devouring its star, you will become master of slow but certain death. 
          Trap enemies in pocket dimensions of fractal geometry, dilate time and haul Quasars at your opposition. Nothing exists forever.
          Venture out into the cold void and become one with the stars.&nbsp;
          <Link to="/gravity" >Join us </Link>&nbsp;and command the most potent force till Heat Death comes for all!</p> 
        </div>
      </FactionContainer>
      <LazyLoad>
        <Link to="/quantum" ><StyledLogo src={`${process.env.PUBLIC_URL}/quantum/quantum_faction.jpg`} alt="Quantum Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/quantum" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/quantum/quantum_faction.jpg`} alt="Quantum Magic" /></Link>
        </LazyLoad>
        <div>
        <h2 className={"header"}>⚛️ Quantum Magic: Unravel a Maze of infinite Possibilities</h2>
        <p>Greetings, traveler of the multiverse! Why live one life, when you can live all of them? 
          Delve into the quantum foam of baseline reality, slip into the loopholes of subatomic dimensions and hop timelines like train tracks. 
          As Quantum-Mage, you are the photon spellslinger of possibilities, a kaleidoscopic storm of quarks defying all causality.
          Virtualize yourself in and out of existence in a dazzling display of color-charged madness.
          We are you, you are us, entangled as one in an infinite wave.&nbsp;<Link to="/quantum" >Come</Link>&nbsp;superposition yourself, for Quantum-Immortality is all but certain!</p>
        </div>
      </FactionContainer>
      <LazyLoad>
        <Link to="/atomic" ><StyledLogo src={`${process.env.PUBLIC_URL}/atomic/atomic_faction.jpg`} alt="Atomic Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/atomic" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/atomic/atomic_faction.jpg`} alt="Atomic Magic" /></Link>
        </LazyLoad>
        <div>
        <h2 className={"header"}>☢️ Atomic Magic: Become Death, Destroyer of Worlds</h2>
        <p>Salutations, valiant warrior! Can you feel the static buzz in the air? Can you hear the glazed sand cracking beneath your boots? 
          If the radiance of a thousand suns were to burst at once into the sky, that would be like the splendor of your might. 
          The holy Trinity of forces beckons to those with an unquenchable thirst for power! 
          Nothing may break your strong-force armor, no one may prevail in the fury of lightning and irradiation.
          Revel in the rush of unending war, for there's energy yearning to be liberated!&nbsp;<Link to="/atomic" >Join</Link>&nbsp;the Legion and march on the Atomic Path.</p>
        </div>
      </FactionContainer>
      <LazyLoad>
        <Link to="/planet" ><StyledLogo src={`${process.env.PUBLIC_URL}/planet/planet_faction.jpg`} alt="Planet Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/planet" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/planet/planet_faction.jpg`} alt="Planet Magic" /></Link>
        </LazyLoad>
        <div>
        <h2 className={"header"}>🌎 Planetary Magic: Become One with the Elements</h2>
        <p>Hail to thee, Gaia's champion! Feel the ground beneath your feet, the gas storms crackling above and the waves crashing afar. You have chosen this world, and it has chosen you.
          Heed your planet's call! From deep within its molten core to the icy heights of its stratosphere, the planetary layers are yours to command. Draw upon the versatility of ley lines and your mastery over the elements will be unparalleled. 
          Terraform new planets and cleanse the intruders!
          If you seek harmony with the world and are ready to defend it,&nbsp;<Link to="/planet" >answer</Link>&nbsp;the call and become a guardian of your world.</p>
        </div>
      </FactionContainer>
      <LazyLoad>
        <Link to="/alchemy" ><StyledLogo src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction.jpg`} alt="Alchemy Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/alchemy" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction.jpg`} alt="Alchemy Magic" /></Link>
        </LazyLoad>
        <div>
        <h2 className={"header"}>⚗️ Alchemy: Discover the Power of Transformation</h2>
        <p>Welcome, student of the Arcane Arts! You know that the only true constant is Change, and as the oldest form of Magic, Alchemy is the study of Change.
          The Alchemists in their search for gold discovered many other things of greater value. 
          But secrets ought to be guarded, for knowledge is power. Cook up volatile acids, healing alloys and transmuting elixirs. The battlefield is your lab, and once all ingredients are in place, you will become unstoppable.
          If you are ready to prove intellect and cunning, you may&nbsp;<Link to="/alchemy" >join</Link>&nbsp;the House of Alchemists and shape the world in your image.</p>
        </div>
      </FactionContainer>
      <LazyLoad>
        <Link to="/life" ><StyledLogo src={`${process.env.PUBLIC_URL}/life/life_faction.jpg`} alt="Life Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/life" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/life/life_faction.jpg`} alt="Life Magic" /></Link>
        </LazyLoad>
        <div>
          <h2 className={"header"}>🦠 Biology Magic: Succumb to Eternal Hunger</h2>
        <p>Bio- and Necromancers, assimilate the Inanimate! Stirring, growing, feasting, nothing quells your thirst.
          Death and decay are but enzymatic tools in your genetic arsenal: the Code of Life is robust and mutant. 
          Spread your viruses like wings, infect your enemies with fungal spores and raise them as undead abominations.
          Life Mages imbibe this essence to overcome any adversity, for it is not the strongest that survives, nor the most intelligent - it is the one most adaptable to change.
          Immortality awaits if you dare shed your mortal shell.&nbsp;<Link to="/life" >Be consumed</Link>&nbsp;by the Hive and become Master over Life and Death!</p>
        </div>
      </FactionContainer>
      <LazyLoad>
        <Link to="/psycho" ><StyledLogo src={`${process.env.PUBLIC_URL}/psycho/psycho_faction.jpg`} alt="Psycho Magic" /></Link>
      </LazyLoad>
      <FactionContainer>
        <LazyLoad>
          <Link to="/psycho" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/psycho/psycho_faction.jpg`} alt="Psycho Magic" /></Link>
        </LazyLoad>
        <div>
        <h2 className={"header"}>🧠 Psychic Magic: Tap into the Paranormal</h2>
        <p>You are expected, walker of the inner realms! The lunatics have whispered your name in their dreams, your influence like a shadow in their minds.
          You are a savant telepath, projecting your spirit into the subconscious of intelligences, natural and artifical alike. 
          You clutch around their brains like spider legs, weaving and washing until there is only you. Play with their hopes, their fears, for their thoughts are yours to command.
          Relish in their souls to fuel esoteric power.&nbsp;<Link to="/psycho" >Come home</Link>&nbsp;to the House of the Psychic.</p>
       
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background3>
   
    <Footer /> */}
    </>
  );
};


export default LandingPage;