import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import styles from './styles/Gallery.module.css';
import Footer from './Footer';

//const bg = '/bg/tall_bg_dark.png';
const bg = '/bg/wide_bg_clean.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const Content = styled.section`
  text-align: left;
  margin: 20px;
  padding-top: 20px; // Add padding to the top
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0.5em; // Add some top margin to all headers
    margin-bottom: 0.5em; // Add some bottom margin to all headers
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const SuccessMessageContainer = styled.div`
  background-color: #4caf50; /* Green */
  color: white;
  padding: 15px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center; /* This will center the buttons horizontally */
`;


const Button = styled.button`
  background: #DAA520;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #c69518;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const DecksContainer = styled.div`
  margin: 20px;
`;

const DecksList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const DeckItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const DeckName = styled.span`
  flex: 1;
  padding-right: 10px;
  font-size: 18px;
`;

const Spacer = styled.div`
  width: 10px; /* Adjust the width as needed */
`;

const fetchPublishedDecks = async () => {
  try {
    const response = await fetch('/api/published_decks');
    if (!response.ok) {
      throw new Error(`Error fetching published decks: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
};

const fetchPublishedDeck = async (deckId) => {
    try {
      const response = await fetch(`/api/published_deck?deck_id=${deckId}`);
      if (!response.ok) {
        throw new Error(`Error fetching published deck: ${response.statusText}`);
      }
      return response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  
  const addCardsToBasket = async (userId, cardIds) => {
    console.log(userId);
    console.log(cardIds)
    try {
      const response = await fetch('/api/basket_add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: userId, card_ids: cardIds }),
      });
      if (!response.ok) {
        throw new Error(`Error adding cards to basket: ${response.statusText}`);
      }
      return response.json();
    } catch (error) {
      console.error(error);
    }
  };
  

  
const Decklists = ( {user} ) => {
  const [publishedDecks, setPublishedDecks] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchDecksData = async () => {
      const decks = await fetchPublishedDecks();
      setPublishedDecks(decks);
    };

    fetchDecksData();
    }, [user]);

  const handleDeckClick = async (deckId) => {
    const deck = await fetchPublishedDeck(deckId);
    setSelectedDeck(deck);
  };

  const handleLoadToBasket = async (deck) => {
    console.log(deck);
    console.log(user.uid);
    if (user) {
      await addCardsToBasket(user.uid, deck.cardIds);
      setSuccessMessage('Cards added!');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
    }
  };

  const SuccessMessage = ({ message }) => (
    <SuccessMessageContainer>
      {message}
      <Link to="/basket" style={{ color: 'white', textDecoration: 'underline', marginLeft: '10px' }}>
        Go to Basket
      </Link>
    </SuccessMessageContainer>
  );
  

  return (
    <>
      <Background>
        <Content>
          <div className="container">
            <h2 className="header">Published Decks 📜</h2>
            {successMessage && <SuccessMessage message={successMessage} />}
            <p>Share public decklists</p>
            <div>
              {selectedDeck ? (
                <div>
                  <h1 className="title">{selectedDeck.deckName} by {selectedDeck.createdBy}</h1>
                  <ButtonContainer>
                    <Button onClick={() => setSelectedDeck(null)}>⬅️ Back to Decklists</Button>
                    <Button onClick={() => handleLoadToBasket(selectedDeck)}>✅ Add to Basket</Button>
                  </ButtonContainer>
                  <div className={styles.galleryContainer}>
                    <div className={styles.imageGrid}>
                      {selectedDeck.cardIds.map((card_id, index) => (
                        <LazyLoad
                          key={index}
                          height={200}
                          offset={100}
                          className={styles.imageWrapper}
                          placeholder={<div>Loading...</div>}
                        >
                          <img
                            src={`${card_id}`}
                            alt={card_id}
                            className={styles.image}
                          />
                        </LazyLoad>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <DecksContainer>
                  <DecksList>
                    {publishedDecks.map((deck) => (
                      <DeckItem key={deck.id}>
                        <DeckName>{deck.deckName}</DeckName>
                        <ButtonContainer>
                          <Button onClick={() => handleDeckClick(deck.id)}>👀 View</Button>
                        </ButtonContainer>
                      </DeckItem>
                    ))}
                  </DecksList>
                </DecksContainer>
              )}
            </div>
          </div>
        </Content>
      </Background>
      <Footer />
    </>
  );
};

export default Decklists;