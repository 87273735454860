import React, { useEffect, useState }  from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import './styles/content.css';
import axios from 'axios';
import "./styles/reset.css";
import { SpacerMedium, SpacerXSmall } from './styles/globalStyles';
import { FaYoutube } from 'react-icons/fa';
import ImageDisplay from './ImageDisplay'; // Import the ImageDisplay component
import { Link } from 'react-router-dom';


// Demo Latent Space Surfer
const bg = './bg/wide_bg_purple.png';
const POLL_INTERVAL = 10000;  // Poll every 10 seconds

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const TrackingLink = styled.a`
  color: #DAA520;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;


const Demo = ({isLogged, handleLogin, userName, user}) => {
  const [activeTab, setActiveTab] = useState('basic');
  const [type, setType] = useState('Unit');
  const [faction, setFaction] = useState('Random');
  const [name, setName] = useState('');
  const [keywords, setKeywords] = useState('');
  const [classes, setClasses] = useState('Creature');
  const [species, setSpecies] = useState('');
  const [effects, setEffects] = useState('');
  const [rules, setRules] = useState('');
  const [ap, setAp] = useState('0');
  const [hp, setHp] = useState('0');
  const [ch, setCh] = useState('None');
  const [pr, setPr] = useState('');
  const [power, setPower] = useState('1');
  const [cost, setCost] = useState('0');
  const [artstyle, setArtstyle] = useState('Random');
  const [flavor, setFlavor] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);
  const [jobId, setJobId] = useState(null); 
  const [advanced, setAdvanced] = useState(false); 
  const sharedStates = {
    type, setType, 
    faction, setFaction,
    name, setName, 
    keywords, setKeywords, 
    classes, setClasses, 
    species, setSpecies, 
    effects, setEffects, 
    ap, setAp, 
    hp, setHp, 
    ch, setCh, 
    pr, setPr, 
    power, setPower, 
    artstyle, setArtstyle,
    flavor, setFlavor,
    imageSrc, setImageSrc,
    isWaiting, setIsWaiting, 
    jobId, setJobId,
    rules, setRules,
    cost, setCost
};
  useEffect(() => {
    let newClasses = classes;

    if (type === 'Spell') {
      newClasses = 'Sorcery';
    } else if (type === 'Relic') {
      newClasses = 'Place';
    } else {
      newClasses = 'Creature';
    }
    setClasses(newClasses);
  }, [type]);

  useEffect(() => {
      if (type !== 'Unit' && classes !== 'Equipment') {
        setAp('None');
      } else {
        setAp('0');
    }
  }, [type, classes]);

  useEffect(() => {
      if (type !== 'Unit' && classes !== 'Equipment') {
        setHp('None');
      } else {
        setHp('0');
    }
  }, [type, classes]);

  useEffect(() => {
    if(type !== 'Relic' || classes !== 'Consumable'){
        setCh('None');
    } else {
        setCh('0'); // or any default value you prefer for charges when it's Consumable and Relic
    }
  }, [type, classes]);


  const submitJobAndCheckStatus = async (card_ai) => {
    try {
      setIsWaiting(true);

      // Submit the job
      const responseFromFlask = await fetch('/api/submit-job', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ responseText: JSON.stringify(card_ai) }),
      });

      if (responseFromFlask.ok) {
        const { jobId } = await responseFromFlask.json();
        setJobId(jobId);

        // Start checking job status
        checkJobStatus(jobId);
      } else {
        console.error(`Job submission failed: ${responseFromFlask.statusText}`);
      }
    } catch (error) {
      console.error('Fetching failed', error);
    }
  };

  const checkJobStatus = async (jobId) => {
    //console.log(`/api/job-status?jobId=${jobId}`)
    try {
      const statusResponse = await fetch(`/api/job-status?jobId=${jobId}`);
      if (!statusResponse.ok) {
          console.error('Server response:', await statusResponse.text());
          throw new Error('Server response was not ok');
      }
      const data = await statusResponse.json();
      if (data.status === 'completed') {
        setImageSrc(data.result);
        setIsWaiting(false);
      } else {
        setTimeout(() => checkJobStatus(jobId), POLL_INTERVAL);
      }
    } catch (error) {
      console.error('Status checking failed', error);
      setIsWaiting(false);
    }
  };

  const handleSubmit = async () => {
    setIsWaiting(true);
    //let card1 = `\nType: ${type}\nClass: ${classes}\nSpecies: ${species}\nColor: ${faction}\nName: ${name}\nKeyword: ${keywords}\nModifier: ${effects}\nAP: ${ap}\nHP: ${hp}\nCharges: ${ch}`; 
    let effects_ai3 = effects; 
    let prompt_ai = pr; 
    let species_ai = species; 
    let superType = 'Summon'
    if (type === 'Spell') { superType = 'Prompt'; } 
    const card_ai = {
          //Cost: parseInt(cost_ai, 10),
          Supertype: superType,
          Type: type,
          Cost: parseInt(cost, 10),
          Class: classes,
          Race: species_ai,
          Color: faction,
          Name: name,
          Keyword: keywords.split(','),
          Modifier: effects_ai3.split('.'),
          Rules: rules,
          AP: ap === "None" ? null : parseInt(ap, 10),
          HP: hp === "None" ? null : parseInt(hp, 10),
          Charges: ch === "None" ? null : parseInt(ch, 10),
          Points: parseInt(power, 10),
          Prompt: prompt_ai,
          Flavor: flavor,
          Model: artstyle,
          Advanced: advanced
        };        
      //console.log(card_ai);
      submitJobAndCheckStatus(card_ai);
  };

  return (
    <>
      <Background>
        <div className={"container"}>
          <Content>
            <h2 className={"header"}>Community Card Creator</h2>
            <p>Create custom cards in your name for the official <Link to="/gallery" style={{ color: '#DAA520' }}>Gallery.</Link> To make a card, populate the fields below or leave them blank, then hit 'Submit'. Read up on the&nbsp;<Link to="/factions" style={{ color: '#DAA520' }}>Sciences.</Link> <p /> 
            <a style={{ color: '#DAA520' }}>Basic:</a> Start here. Free design, the rest is up to the Gods.<p /> 
            <a style={{ color: '#DAA520' }}>Advanced:</a> Read the <TrackingLink href="https://www.reddit.com/r/eigendark/comments/1c1crhn/design_guide_for_prompt_wizards_and_aspiring_ones/" target="_blank" rel="noopener noreferrer">Subreddit</TrackingLink>. Complete freedom, but adhere to your Science Doctrine, lest its God may alter or reject your Summoning. Cards pending approval are white, approved ones black.<p />
            {/*<a href="https://www.youtube.com/watch?v=_-UCVZoPv4g&t=84s" target="_blank" rel="noopener noreferrer">
              <FaYoutube /> Watch Demo on YouTube
            </a>*/}
            </p>
            <br />
            <div className="tabs">
                <button 
                    className={`tab-button ${activeTab === 'basic' ? 'active' : ''}`} 
                    onClick={() => (setActiveTab('basic'), setPower('1'), setArtstyle('Random'), setAdvanced(false))}
                >
                    Basic
                </button>
                <button 
                    className={`tab-button ${activeTab === 'advanced' ? 'active' : ''} strikethrough`} 
                    onClick={() => (setActiveTab('advanced'), setPower('0'), setAdvanced(true))}
                >
                    Advanced
                </button>
                <div className="tab-spacer"></div>
            </div>
            <div className="tab-content">
                {activeTab === 'basic' && <BasicTab {...sharedStates} />}
                {activeTab === 'advanced' && <AdvancedTab {...sharedStates} />}
            </div>
            {isWaiting ? ( 
              <p className="loading-message">🕯 Summoning Latent Space ... 🕯</p>
            ) : (
              <button className="button" onClick={handleSubmit}>Submit (づ ᴗ _ᴗ)づ</button>
            )}
            <div className="center-container">
            {isWaiting ? (
              <>
                <div className="spinner" />
              </>
            ) : (
              <ImageDisplay imageSrc={imageSrc} isLogged={isLogged} handleLogin={handleLogin} jobId={jobId} userName={userName} user={user} />
            )}
            {/*<div className="video-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/_-UCVZoPv4g?si=bfI8eEuaC3yuG1yP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>*/}
          </div>
          </Content>
        </div>
      </Background>
      <Footer />
    </>
  );
};

const BasicTab = ({
    type, setType, 
    faction, setFaction, 
    name, setName, 
    classes, setClasses, 
    species, setSpecies, 
    pr, setPr,
    power, setPower,
    flavor, setFlavor,
}) => {
  return (
      <>
            <form>
              <label style={{ marginRight: '10px' }}>
              Type:
                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <>
                      <option value="Unit">Unit</option>
                      <option value="Relic">Relic</option>
                      <option value="Spell">Spell</option>
                    </>
                </select>
              </label>
              <label style={{ marginRight: '10px' }}>
              | Faction:
                <select value={faction} onChange={(e) => setFaction(e.target.value)}>
                  <option value="Gravity">Gravity</option>
                  <option value="Quantum">Quantum</option>
                  <option value="Atomic">Atomic</option>
                  <option value="Planet">Planet</option>
                  <option value="Alchemy">Alchemy</option>
                  <option value="Biology">Biology</option>
                  <option value="Psychic">Psychic</option>
                  <option value="Glitch">Glitch</option>
                  <option value="Random">Random</option>
                </select>
              </label>
              <br />           
              <label style={{ marginRight: '10px' }}>
              Name:
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Blank: auto-fill"/>
              </label>
              <label style={{ marginRight: '10px' }}>
              | Power:
              <select value={power} onChange={(e) => setPower(e.target.value)}>
                  {[...Array(10).keys()].map((num) => (
                      <option key={num} value={num + 1}>{num + 1}</option>
                  ))}
              </select>
              </label>
              <br />  
              <label style={{ marginRight: '10px' }}>
              Class:
                <select value={classes} onChange={(e) => setClasses(e.target.value)}>
                  {type === "Spell" ? (
                    <>
                      <option value="Sorcery">Sorcery</option>
                      <option value="Psychology">Psychology</option>
                      <option value="Biology">Biology</option>
                      <option value="Chemistry">Chemistry</option>
                      <option value="Math">Math</option>
                      <option value="Physics">Physics</option>
                      <option value="Chaos">Chaos</option>
                      <option value="Nuclear">Nuclear</option>
                      <option value="Climate">Climate</option>
                      <option value="Necromancy">Necromancy</option>
                      <option value="Pyromancy">Pyromancy</option>
                      <option value="Cryomancy">Cryomancy</option>
                      <option value="Ritual">Ritual</option>
                      <option value="Miracle">Miracle</option>
                      <option value="Entropy">Entropy</option>
                      <option value="Exploit">Exploit</option>
                      <option value="Parasite">Parasite</option>
                      <option value="Geology">Geology</option>
                      <option value="Law">Law</option>
                      <option value="Economics">Economics</option>
                    </>
                  ) : type === "Unit" ? (
                    <>
                      <option value="Creature">Creature</option>
                    </>
                  ) : type === "Relic" ? (
                    <>
                    <option value="Place">Place</option>
                    <option value="Equipment">Equipment</option>
                    <option value="Consumable">Consumable</option>
                    </>
                  ) : null}
                </select>
              </label>
              <label style={{ marginRight: '10px' }}>
              | Species:
                <input
                    type="text"
                    value={species}
                    onChange={(e) => setSpecies(e.target.value)}
                    placeholder="Blank: auto-fill"
                  />
              </label>
              <br />
              <label style={{ marginRight: '10px' }}>
              Prompt:
                <input
                    type="text"
                    value={pr}
                    onChange={(e) => setPr(e.target.value)}
                    placeholder="Describe your artwork here. Leave blank for auto-fill."
                    style={{ width: '330px' }} 
                  />
              </label>
              <br />
              <label style={{ marginRight: '10px' }}>
              Flavor: 
                <input
                    type="text"
                    value={flavor}
                    onChange={(e) => setFlavor(e.target.value)}
                    placeholder="Leave blank for auto-fill."
                    style={{ width: '339px' }} 
                  />
              </label>
            </form>
      </>
  );
};

const AdvancedTab = ({
  type, setType, 
  faction, setFaction, 
  name, setName, 
  keywords, setKeywords, 
  classes, setClasses, 
  species, setSpecies, 
  effects, setEffects, 
  ap, setAp, 
  hp, setHp, 
  ch, setCh, 
  pr, setPr, 
  rules, setRules,
  artstyle, setArtstyle,
  flavor, setFlavor,
  cost, setCost
}) => {
  return (
      <>
            <form>
              <label style={{ marginRight: '10px' }}>
              Type:
                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <>
                      <option value="Unit">Unit</option>
                      <option value="Relic">Relic</option>
                      <option value="Spell">Spell</option>
                    </>
                </select>
              </label>
              <label style={{ marginRight: '10px' }}>
              | Faction:
                <select value={faction} onChange={(e) => setFaction(e.target.value)}>
                  <option value="Gravity">Gravity</option>
                  <option value="Quantum">Quantum</option>
                  <option value="Atomic">Atomic</option>
                  <option value="Planet">Planet</option>
                  <option value="Alchemy">Alchemy</option>
                  <option value="Biology">Biology</option>
                  <option value="Psychic">Psychic</option>
                  <option value="Glitch">Glitch</option>
                  <option value="Random">Random</option>
                </select>
              </label>
              <br />   
              <label style={{ marginRight: '10px' }}>
              Name:
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Blank: auto-fill" style={{ width: window.innerWidth >= 1024 ? '245px' : '55%' }} />
              </label>    
              | Cost:
              <select value={cost} onChange={(e) => setCost(e.target.value)}>
                  {[...Array(12).keys()].map((num) => (
                      <option key={num} value={num}>{num}</option>
                  ))}
              </select>    
              <br />    
              <label style={{ marginRight: '10px' }}>
              Class:
                <select value={classes} onChange={(e) => setClasses(e.target.value)} style={{ width: window.innerWidth >= 1024 ? '100px' : '25%' }}>
                  {type === "Spell" ? (
                    <>
                      <option value="Sorcery">Sorcery</option>
                    </>
                  ) : type === "Unit" ? (
                    <>
                      <option value="Creature">Creature</option>
                      <option value="Ninja">Ninja</option>
                      <option value="Artificer">Artificer</option>
                      <option value="Hunter">Hunter</option>
                      <option value="Shapeshifter">Shapeshifter</option>
                      <option value="Warrior">Warrior</option>
                      <option value="Vampire">Vampire</option>
                      <option value="Warlock">Warlock</option>
                      <option value="Assassin">Assassin</option>
                      <option value="Harlequin">Harlequin</option>
                      <option value="Mutant">Mutant</option>
                      <option value="Witch-Doctor">Witch-Doctor</option>
                      <option value="Hacker">Hacker</option>
                      <option value="Lich">Lich</option>
                      <option value="Necromancer">Necromancer</option>
                      <option value="Lord">Lord</option>
                      <option value="Shaman">Shaman</option>
                      <option value="Skeleton">Skeleton</option>
                      <option value="Zombie">Zombie</option>
                      <option value="Cryomancer">Cryomancer</option>
                      <option value="Monk">Monk</option>
                      <option value="Wizard">Wizard</option>
                      <option value="Priest">Priest</option>
                      <option value="Witch">Witch</option>
                      <option value="Agent">Agent</option>
                      <option value="Spy">Spy</option>
                      <option value="Saint">Saint</option>
                      <option value="Alchemist">Alchemist</option>
                      <option value="Spirit">Spirit</option>
                      <option value="Scientist">Scientist</option>
                      <option value="Pyromancer">Pyromancer</option>
                      <option value="Cyber-Runner">Cyber-Runner</option>
                    </>
                  ) : type === "Relic" ? (
                    <>
                    <option value="Place">Place</option>
                    <option value="Equipment">Equipment</option>
                    <option value="Consumable">Consumable</option>
                    </>
                  ) : null}
                </select>
              </label>
              <label style={{ marginRight: '10px' }} >
              | Species:
                <input
                    type="text"
                    value={species}
                    onChange={(e) => setSpecies(e.target.value)}
                    placeholder="Blank: auto-fill"
                    style={{ width: window.innerWidth >= 1024 ?  '161px' : '33%' }} 
                  />
              </label>
              <br />
              <label style={{ marginRight: '10px' }}>
              <label>
              Keywords:
              <input
                    type="text"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    placeholder="KW1, KW2, ..."
                    style={{ width: window.innerWidth >= 1024 ? '310px' : '100%' }} 
                  />
              </label>
              </label>
              <br />
              <br />
              <label style={{ marginRight: '10px' }}>
              <textarea 
                value={effects} 
                onChange={(e) => setEffects(e.target.value)} 
                placeholder="Write custom effect text here or leave blank." 
                style={{ 
                  width: window.innerWidth >= 1024 ? '395px' : '100%', // Adjust width based on screen size
                  height: '66px' 
                }} 
              />
              </label>
              <br />
              <label style={{ marginRight: '10px' }}>
              AP:
                  {(ap !== 'None') ? (
                      <select value={ap} onChange={(e) => setAp(e.target.value)}>
                          {[...Array(11).keys()].map((num) => (
                              <option key={num} value={num}>{num}</option>
                          ))}
                      </select>
                  ) : (
                      <span>None</span>
                  )}
              </label>
              <label style={{ marginRight: '10px' }}>
              | HP:
                  {(hp !== 'None') ? (
                      <select value={hp} onChange={(e) => setHp(e.target.value)}>
                          {[...Array(11).keys()].map((num) => (
                              <option key={num} value={num}>{num}</option>
                          ))}
                      </select>
                  ) : (
                      <span>None</span>
                  )}
              </label>
              <label style={{ marginRight: '10px' }}>
              | Charges:
                  {(ch !== 'None') ? (
                      <select value={ch} onChange={(e) => setCh(e.target.value)}>
                          {[...Array(11).keys()].map((num) => (
                              <option key={num} value={num}>{num}</option>
                          ))}
                      </select>
                  ) : (
                      <span>None</span>
                  )}
              </label>
              <br />
              <br />
              <label style={{ marginRight: '10px' }}>
              <textarea 
                value={pr} 
                onChange={(e) => setPr(e.target.value)} 
                placeholder="Describe your artwork here. Leave blank for auto-fill."
                style={{ 
                  width: window.innerWidth >= 1024 ? '395px' : '100%', // Adjust width based on screen size
                  height: '50px' 
                }} 
              />
              </label>
               {/*
              <label style={{ marginRight: '10px' }}>
              | Model:
                <select value={artstyle} onChange={(e) => setArtstyle(e.target.value)}>
                    <>
                      <option value="Random">Random</option>
                      <option value="Dalle">Dalle</option>
                      {/*<option value="Ultra">Ultra</option>
                      <option value="SD3">SD3</option>
                      <option value="SDXL">SDXL</option>
                      <option value="Shaper">Shaper</option>
                      <option value="schnell">Schnell</option>
                      <option value="flux">Flux</option>
                      <option value="sd3">SD3</option>
                    </>
                </select>
              </label>
              <br />
              {/*<label style={{ marginRight: '10px' }}>
              Reminder Text:
                <input type="text" value={rules} onChange={(e) => setRules(e.target.value)} placeholder="Explain keywords and rules here." style={{ width: '300px' }} />
              </label>
              <br />*/}
              <br />
              <label style={{ marginRight: '10px' }}>
              Flavour:
                <input
                    type="text"
                    value={flavor}
                    onChange={(e) => setFlavor(e.target.value)}
                    placeholder="Leave blank for auto-fill."
                    style={{ width: '330px' }} 
                  />
              </label>
            </form>
            
      </>
  );
};


export default Demo;




