import React, { useState } from 'react';
import { db } from './firebaseConfig'; 
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const ImageDisplay = ({ imageSrc, isLogged, handleLogin, jobId, userName, user }) => {
    const [isSaved, setIsSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    
    const fetchUserIdByUsername = async (username) => {
        const usernameRef = doc(db, 'usernames', username);
        try {
          const docSnap = await getDoc(usernameRef);
          if (docSnap.exists()) {
            return docSnap.data().userId;
          } else {
            console.log('No such username!');
            return null; // or handle as needed
          }
        } catch (error) {
          console.error('Error fetching user ID:', error);
          return null; // or handle as needed
        }
      };

    const saveToGallery = async (jobId, userName, user ) => {
        setIsSaving(true); 
        //const userID = await fetchUserIdByUsername(userName)
        //console.log("UserID:", userID);
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef, { source: 'server' }); //force to fetch from server, not cache
        let creations = userDoc.data().creations || 0;
        creations += 1;
        await setDoc(userRef, { creations: creations }, { merge: true });
        const card_ai = {
          ID: jobId,
          User: userName,
          Creations: creations,
          UID: user.uid
        };

        try {
        const responseFromFlask = await fetch('/api/save', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ responseText: JSON.stringify(card_ai) }),
        });
    
        if (responseFromFlask.ok) {
            //console.log(`Card was successfully saved`);
            setIsSaved(true); 
        } else {
            console.error(`Card submission failed: ${responseFromFlask.statusText}`);
        }
        } catch (error) {
        console.error('Fetching failed', error);
        }
        setIsSaving(false); 
    };

  return (
    <>
{imageSrc && (
  <div>
    <img src={`data:image/png;base64,${imageSrc}`} alt="Generated Content" className="centered-image" />

    {isLogged ? (
            userName ? (
              isSaving ? (
                <div className="spinner-small" />
              ) : isSaved ? (
                <p style={{ color: 'white' }}>Card published! <Link to="/gallery" style={{ color: '#DAA520' }}>CHECK IT!</Link></p>
              ) : (
                <button 
                className="button" 
                style={{ 
                  backgroundColor: '#4CAF50', 
                  color: 'white' 
                }} 
                onClick={() => saveToGallery(jobId, userName, user)}
              >
               🚀 PUBLISH
              </button>
              )
            ) : (
              //<button className="button" >Anon</button>
              <button className="button" onClick={() => saveToGallery(jobId, "Anonymous", user)}>PUBLISH</button>
            )
          ) : (
            <button className="button" onClick={handleLogin} style={{ backgroundColor: '#4CAF50', color: 'white' }} >🔑 Login to Save</button>
          )}
  </div>
)}

    </>
  );
};

export default ImageDisplay;
