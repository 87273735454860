import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions_eth";
import { fetchData } from "./redux/data/dataActions_eth";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Footer from './Footer';
const bg = './bg/bg_ch.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 1025px) {
    min-height: 95vh;
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

//border: 4px dashed var(--secondary);
//border-radius: 100%;
export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  width: 200px;
  opacity: 0.9;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
  @media (max-width: 1024px) {
    display: None;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const MintingPage = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [address, setAddress] = useState("0x521f9C7505005CFA19A8E5786a9c3c9c9F5e6f42");
  const [tokenId, setTokenId] = useState(0);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 10000,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit);
    //console.log("Cost: ", totalCostWei);
    //console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME} cards...`);
    setClaimingNft(true);
    console.log(address,tokenId)
    blockchain.smartContract.methods
      .mint(address,tokenId)
      .send({
        //gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: 0 * 1e9, // 40 gwei to wei
        //maxFeePerGas: web3.utils.toWei('100', 'gwei'), 
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sry, sth went wrong please try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Success!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config_eth.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
        <Background>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} >
          <s.Container flex={1} jc={"center"} ai={"center"}>
          <StyledImg alt={"example"} src={"/bg/eth_sample1.gif"} style={{transform: "scale(0.75)", marginRight: "75px"}} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              //backgroundColor: "rgba(0,0,0,0.5)",
              padding: 12,
              //borderRadius: 24,
              //maxHeight: '512px',
              //width: '50%',
              //height: '50%',
              //border: "4px dashed var(--secondary)",
              //boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.8)",
              padding: 12,
              borderRadius: 24,
              maxHeight: '40vh',
              maxWidth: '35vh',
              //width: '50%',
              //height: '50%',
              //border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
            >
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--primary-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
              <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
              >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY} minted
              </s.TextTitle>
              <s.TextTitle
                  style={{ textAlign: "center", color: "var(--primary-text)" }}
                >

                  {CONFIG.DISPLAY_COST}{" "}{CONFIG.NETWORK.SYMBOL} / card 
                  {/*<s.TextDescription style= {{fontSize: "12"}}>{"(ca. $5)"}</s.TextDescription>*/}
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--primary-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--primary-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--primary-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <br/>
                    <select value={address} onChange={(e) => setAddress(e.target.value)}>
                      <option value="0xED5AF388653567Af2F388E6224dC7C4b3241C544">Azuki</option>
                      <option value="0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D">BAYC</option>
                      <option value="0x31385d3520bCED94f77AaE104b406994D8F2168C">BGAN Punks</option>
                      <option value="0xEEd41d06AE195CA8f5CaCACE4cd691EE75F0683f">Cigawrette Packs</option>
                      <option value="0x1A92f7381B9F03921564a437210bB9396471050C">Cool Cats</option>
                      <option value="0x1CB1A5e65610AEFF2551A50f76a87a7d3fB649C6">Cryptoadz</option>
                      <option value="0x8821BeE2ba0dF28761AffF119D66390D594CD280">DeGods</option>
                      <option value="0x2acAb3DEa77832C09420663b0E1cB386031bA17B">Deadfellaz</option>
                      <option value="0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e">Doodles</option>
                      <option value="0x9690b63Eb85467BE5267A3603f770589Ab12Dc95">FRWG (Warriors)</option>
                      <option value="0x521f9C7505005CFA19A8E5786a9c3c9c9F5e6f42">FRWC (Wizards)</option>
                      <option value="0x251b5F14A825C537ff788604eA1b58e49b70726f">Forgotten Souls</option>
                      <option value="0x9ada21A8bc6c33B49a089CFC1c24545d2a27cD81">Godjira</option>
                      <option value="0x57a204AA1042f6E66DD7730813f4024114d74f37">Cyberkongz</option>
                      <option value="0x8D9710f0e193d3f95c0723eAAF1A81030Dc9116D">Hytopia Worlds</option>
                      <option value="0x8943C7bAC1914C9A7ABa750Bf2B6B09Fd21037E0">Lazy Lions</option>
                      <option value="0xe90d8fb7b79c8930b5c8891e61c298b412a6e81a">Lasogette</option>
                      <option value="0x524cAB2ec69124574082676e6F654a18df49A048">Lil Pudgies</option>
                      <option value="0x5Af0D9827E0c53E4799BB226655A1de152A425a5">Milady Maker</option>
                      <option value="0x79FCDEF22feeD20eDDacbB2587640e45491b757f">Mfer</option>
                      <option value="0xc3f733ca98E0daD0386979Eb96fb1722A1A05E69">Mooncats</option>
                      <option value="0x60E4d786628Fea6478F785A6d7e704777c86a7c6">MAYC</option>
                      <option value="0xBd3531dA5CF5857e7CfAA92426877b022e612cf8">Pudgy Penguins</option>
                      <option value="0xEDc3AD89f7b0963fe23D714B34185713706B815b">PG Gen2</option>
                      <option value="0x364C828eE171616a39897688A831c2499aD972ec">Sappy Seals</option>
                      <option value="0x1D20A51F088492A0f1C57f047A9e30c9aB5C07Ea">Wassies</option>
                      <option value="0x85f740958906b317de6ed79663012859067E745B">Wicked Craniums</option>
                    </select>
                    <br/>
                    <label style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "var(--primary-text)",
                    }}>
                      Enter Token ID: &nbsp;
                      <input
                        type="number"
                        value={tokenId}
                        onChange={(e) => {
                          const newId = parseInt(e.target.value, 10);
                          if (!isNaN(newId) && newId >= 0) {
                            setTokenId(newId);
                          }
                        }}
                        placeholder="ID"
                        style={{ width: '60px' }}
                      />
                    </label>
                    <br />
                    <s.SpacerLarge/>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            </s.Container>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
          <StyledImg alt={"example"} src={"/bg/eth_sample2.gif"} style={{transform: "scale(0.75)", marginLeft: "75px"}} />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerLarge />
        </Background>
      <Footer />
    </s.Screen>
  );
}

export default MintingPage;
