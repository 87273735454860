import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_gr.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  margin-right: 15px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Gravity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/gravity/gravity_faction0.jpg`} alt="Gravity Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/gravity/gravity_faction0.jpg`} alt="Gravity Magic" />
        <div>
          <h2 className={"header"}>Gravity Magic ☉</h2>
          <p>The Gravity House welcomes those who yearn for control. It envisions a world of absolute order and symmetry, a celestial orchestra where every planet, star, and galaxy moves according to the grand cosmic design. 
          With a profound understanding of Einstein's relativity, they tweak time dilation and contraction with ease, freezing their enemies in a state of suspended animation or expediting the passage of time. 
          To face a Gravity Adept is to grapple with the creeping stasis of the interstellar void, a pristine stagnation in the freezing cold of space.
          Gravity is playing the long game with reactive control. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/gravity/gravity_faction1.jpg`} alt="Gravity Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/gravity/gravity_faction1.jpg`} alt="Gravity Magic" />
        <div>
          <p>
          A Gravimancer is a steadfast, indomitable entity, akin to a black hole in the vast expanse of the cosmos. They don high-tech, AI-infused space gear, their attire a fusion of an astronaut's suit and mystical robes. Their scepter, a conduit for their potent spells, pulsates with the energy of captured stars. 
          <br />
          Their Eyes flicker with the light of distant quasars, a testament to their profound connection with the universe. These Adepts could have been navigators on deep-space exploration vessels, astrologists or scientists in large orbital observatories before their magical potential awakened.       
          <br />
          For them, victory is akin to conducting the symphony of the cosmos, exerting absolute control over the universe. Defeat, however, resembles a star falling into oblivion, a plunge into the event horizon.
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/gravity/gravity_faction2.jpg`} alt="Gravity Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/gravity/gravity_faction2.jpg`} alt="Gravity Magic" />
        <div>
          <p>
          Gravity Mages are the architects and city planners of this universe, their deep understanding of space-time allowing them to mold and manipulate the physical world. Their structures defy conventional designs, creating cities that fold into themselves and seem to exist in multiple places at once—a showcase of gravitational elegance.
          <br />
          Representing the grandeur of the cosmos, the Gravity deck is packed with space-time bending cards that allow control over the field. Their effects range from slowing down opponents' actions to accelerating your own. This deck gives the feeling of manipulating the game's pace, captivating fans of cosmology and astrophysics with beautiful artwork of galaxies, black holes, and celestial bodies. 
          With the Gravity deck, you become a cosmic conductor directing the symphony of the universe.
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/create" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Gravity;

