import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import styles from './styles/Gallery.module.css';
import Footer from './Footer';

const bg = '/bg/bg_al3.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: 100vw auto; /* 100% width of the viewport, height will auto-scale */
  background-repeat: repeat-y; /* Repeat the background vertically only */
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {}

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const SuccessMessageContainer = styled.div`
  background-color: #4caf50; /* Green */
  color: white;
  padding: 15px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px; /* Space between buttons */
  align-items: center; /* Center align items vertically */
  margin: 10px 0;
`;

const Button = styled.button`
  background: #DAA520;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #c69518;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const DeckInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const fetchBasket = async (userId) => {
  const response = await fetch(`/api/basket?user_id=${userId}`);
  return response.json();
};

const clearBasket = async (userId) => {
  await fetch('/api/basket_clear', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_id: userId }),
  });
};

const saveDeck = async (userId, deckName, cardIds) => {
  await fetch('/api/deck_save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_id: userId, deck_name: deckName, card_ids: cardIds }),
  });
};

const removeFromBasket = async (userId, cardId) => {
  await fetch('/api/basket_remove', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_id: userId, card_id: cardId }),
  });
};

const Basket = ({ user }) => {
  const [basket, setBasket] = useState([]);
  const [deckName, setDeckName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchBasketData = async () => {
      if (user) {
        const basketItems = await fetchBasket(user.uid);
        setBasket(basketItems);
      }
    };

    fetchBasketData();
  }, [user]);

  const handleClearBasket = async () => {
    await clearBasket(user.uid);
    setBasket([]);
  };

  const handleRemoveFromBasket = async (cardId) => {
    await removeFromBasket(user.uid, cardId);
    setBasket(basket.filter(item => item.card_id !== cardId));
  };

  const handleSaveDeck = async () => {
    const cardIds = basket.map(item => item.card_id);
    await saveDeck(user.uid, deckName, cardIds);
    setSuccessMessage('Decklist saved!');
    setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
  };

  const SuccessMessage = ({ message }) => (
    <SuccessMessageContainer>
      {message}
      <Link to="/decks" style={{ color: 'white', textDecoration: 'underline', marginLeft: '10px' }}>
        Go to Decks
      </Link>
    </SuccessMessageContainer>
  );

  return (
    <>
      <Background>
        <Content>
          <div className="container">
            <h2 className="header">Your Basket</h2>
            <p>Select cards from <Link to="/gallery" style={{ color: '#DAA520' }}>Gallery</Link> to add them to your basket or import a saved <Link to="/decks" style={{ color: '#DAA520' }}>Deck</Link> list.</p>
            <ButtonContainer>
              <Button>
                <Link to="/checkout">Buy 🛒</Link>
              </Button>
              <Button onClick={handleClearBasket}>Clear 🗑</Button>
            </ButtonContainer>
            <ButtonContainer>
              <DeckInput
                type="text"
                value={deckName}
                onChange={(e) => setDeckName(e.target.value)}
                placeholder="Enter deck name"
              />
              <Button onClick={handleSaveDeck}>Save</Button>
            </ButtonContainer>
            {successMessage && <SuccessMessage message={successMessage} />}
            <div className={styles.galleryContainer}>
              <div className={styles.imageGrid}>
                {basket.map((item) => (
                  <LazyLoad
                    key={item.id}
                    height={200}
                    offset={100}
                    className={styles.imageWrapper}
                    placeholder={<div>Loading...</div>}
                  >
                    <img
                      src={`${item.card_id}`}
                      alt={item.card_id}
                      className={styles.image}
                    />
                    <Button onClick={() => handleRemoveFromBasket(item.card_id)}>Remove</Button>
                  </LazyLoad>
                ))}
              </div>
            </div>
          </div>
        </Content>
      </Background>
      <Footer />
    </>
  );
};

export default Basket;
