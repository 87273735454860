import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_al.png';
import './styles/content.css';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  color: var(--primary-text);

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

`;

const InfoPage = () => {
  return (
    <>
    <Background>
    <div className={"container"}>
      <Content>
      <h1 className={"title"}>Alpha Roadmap</h1>
      <h1 className={"question"}>1: Launch Collection Alpha</h1>
      <p></p>
      <br></br>
      <h1 className={"question"}>2: Promote Community </h1>
      <br></br>
      <p></p>
      <h1 className={"question"}>3: Physical Twins + Shipping</h1>
      <p></p>
      <br></br>
      <h1 className={"question"}>4: Digital Game</h1>
      <p></p>
      </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default InfoPage;
