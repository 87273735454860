import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_al.png';
import './styles/content.css';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  color: var(--primary-text);

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Rules = () => {

  return (
    <>
    <Background>
    <div className={"container"}>
      <Content>
      <h1 className={"title"}>The Rules of Eigendark</h1>
      <section className={"content"}>
        <p>Eigendark is a card game where two or more players face off with their own decks. Each deck must be built from 50 Eigendark cards. There are no other limitations to deck building.</p>

        <h2 className={"header"}>Win Condition</h2>
        <p>A player loses the match when they run out of Souls or cards in their deck. A player wins the match when all their enemies are defeated. The match ends in a draw if players lose at the same time.</p>

        <h2 className={"header"}>Areas</h2>
        <ul>
          <li>
            <strong>Play:</strong> this is the area where all played cards are put, visible for everyone to see. The Play is categorized into 4 zones: The Summon Zone, the Stack, the Bank and Combat. When played, Summons (Units and Relics) are put into the Summon Zone, Powers are put onto the Stack and Catalysts are put into the Bank. The Combat zone is reserved for attacking and blocking Units during Combat.
          </li>
          <li>
            <strong>Deck, Hand:</strong> Areas for Deck cards and Hand cards respectively. Except from their amount, both are hidden from other players by default.
          </li>
          <li>
            <strong>Trash:</strong> this is the discard pile, where Powers are put when resolved and Summons when destroyed. Cards milled from the deck and discarded hand cards are also put into Trash. Each player’s Trash is openly visible.
          </li>
          <li>
            <strong>Exile:</strong> this is a special area reserved for cards that are exiled. By default, exiled cards are visible.
          </li>
        </ul>
        <p></p>
        <h2 className={"header"}>Card types</h2>
        <p>Summons are cards that generally remain in Play when played. Powers are cards that are put into Trash when their effect was resolved. Catalysts are Summons or Powers that are put upside-down into the Bank. One Catalyst can be exhausted to pay for 1 Mana. Exhausted Catalysts are tilted at an angle to signify that they cannot be exhausted again until replenished.</p>

        <h2 className={"header"}>Beginning a new match</h2>
        <p>Each player starts out with 20 Souls and a 50-card randomly shuffled deck. At the beginning of the match, each player draws 4 cards. They have the option to pick 0-4 of those cards to shuffle back into the deck. When they do, they draw as many cards until their hand size equals 4 again. This is called Mulligan. Finally, the starting player is randomized. The starting player will be given Initiative on the first turn.</p>

        <h2 className={"header"}>Turns, Initiative and player actions</h2>
        <p>A match progresses iteratively over turns, where players are given the opportunity to act when they have Initiative. At the beginning of every turn, each player regenerates all Catalysts in their Bank, draws 2 cards from their deck and mobilizes 1 Portal. Portals are an accumulating consumable utilized for attacking, more on this later. All players share the same turn but can only play cards when on Initiative.</p>
        <p>Within a turn, player take rounds choosing actions. The active player is the one that currently holds Initiative and needs to choose an action. They have several options to choose from:</p>
        <ul className={"list"}>
          <li>1- Play cards by paying their respective cost in Mana. Summons are placed in the Summon Zone and Powers onto the Stack.</li>
          <li>2- Place a hand card as Catalyst by putting it upside-down into the Bank. This action is limited to once per turn by default.</li>
          <li>3- Recall a Catalyst with a hand card. The hand card will become the new Catalyst, the former Catalyst is returned to Hand.</li>
          <li>4- Attacking another player by declaring attackers and sacrificing a Portal.</li>
          <li>5- Blocking attackers by declaring blockers.</li>
          <li>6- Activate a Skill.</li>
          <li>7- Do nothing.</li>
        </ul>
        <p></p>
        <p>When there are cards on the stack, no action can be taken other than playing another Power, activating a Skill or doing nothing. When any action has been taken by the active player, they pass Initiative to the next player, which is by default the right-hand player. A turn ends when all players have passed and done nothing in a row. The first player to do nothing in this row will claim Initiative for the next turn and become the first active player.</p>
        <p>Example: Let’s say Alice is the active player and she decides to pass Initiative to Bob. Bob passes to Charlie. Charlie passes to Alice. All 3 players have passed in a row doing nothing, the turn ends and Alice will become the first active player with Initiative for the next turn. When a turn ends, all empty Catalysts are turned face-down. They are “charged” as long as they are face-down. A charged Catalyst can be turned face-up again to pay 1 Mana for Powers, but they can also be exhausted normally to pay for any other card. If a charged Catalyst is recalled, the new Catalyst will become also charged (put face-down). Effects that trigger on turn end will be put on the Stack, then after resolution, the new turn begins.</p>

        <h2 className={"header"}>Playing cards</h2>
        <p>A card is played by paying for its cost, given in the upper right corner of the card, usually in Mana. Mana is generated by exhausting Catalysts. Unless otherwise stated, exhausting 1 Catalyst yields 1 Mana of the faction stated on the card. A faction is given by the symbol in the card’s upper left corner and the faction name on the bottom. So, when playing a card, a player needs to spend at least 1 Mana generated from its faction. Exhausted Catalysts are empty, regenerated Catalysts are full.</p>
        <p>If targets need to be chosen when playing a card, and there are insufficient valid targets, the card cannot be played.</p>
        <p> Playing cards is not the same as summoning cards. Summoned cards are also put into the Summon Zone but are not considered played.</p>
        <p> The following actions can only be done when the Stack is empty:</p>
        <ul>
        <li>1- Playing Summons</li>
        <li>2- Placing and/or recalling Catalysts</li>
        </ul>
        <p></p>
        
        <h2 className={"header"}>The Stack</h2>
        <p>Powers are put onto the Stack when played. Effects triggered from Skills are also put on the Stack where they are treated as if they were Power cards. </p>
        <p>When there is a card on the Stack already, the newly played card will be put on top. A card stays on the Stack until its text is resolved. A resolved Power is put into Trash.</p>
        <p>The Stack starts resolving its cards when all players have passed Initiative in a row, similarly to how a turn ends. The first player to do nothing in a row will become the active player when the Stack is resolved. For example, if Alice plays a Power she must pass Initiative to her opponent, Bob. Bob chooses not to do anything, instead he directly passes back to Alice. She also passes. Now, the Stack gets resolved and Bob continues to be the active player.</p>
        <p>Stack resolution starts from the top and progresses towards the bottom. When the resolution of a card triggers another effect, it is put on top of the Stack to be resolved next. During Stack resolution, no player can take any action.  </p>
        <p>If targets or conditions stated in the text have become invalid, the effect fizzles (resolves without doing anything).</p>
        <p>Powers with the keyword “Burst” resolve immediately.</p>
        <p>When multiple effects trigger at the same time, if controlled by the same player, their order is randomized. If not, they are put onto the Stack beginning with the active player and moving on to the next. Triggered Effects do not pass Initiative.</p>

        <h2 className={"header"}>Combat</h2>
        <p>Combat starts when a player declares attackers against another player and sacrifices a Portal. It ends when Battle is resolved. The next player who got passed Initiative from the attacking player will become the active player after Combat.
        </p>
        <strong>Attacking:</strong>
        <p>For declaring attackers, the attacking player moves the attacking Units into Combat, a separate zone in the Play area. The attacking Units have to be arranged clearly visible from left to right next to each other. This is because Battle is resolved from the left-most attacker to the right-most one.</p>
        <p>For this action, the player may also play any number of Powers and Skills. These are put onto the Stack as usual, but don’t pass Initiative. The Combat Stack is resolved before Battle, after blockers have been declared. Powers with the keyword “Burst” resolve immediately.</p>
        <strong>Blocking:</strong>
        <p>When attackers are declared, 1 Portal sacrificed and optionally cards/effects put on the Stack, the defending player may choose to declare blockers and put cards/effects on the Stack.</p>
        <p>Blockers are declared by moving the blocking Units into Combat, opposing the Units they block. In addition, the defending player may put Powers/Effects on the Stack like the attacking player. Similarly, this will not pass Initiative. An attacker is considered blocked when it is opposed by a blocking Unit.</p>

        <strong>Battle:</strong>
        <p>When the defending player is done declaring blockers, the Stack gets resolved. After Stack resolution, attacking Units battle defending Units, starting from the left attacker (POV of the attacking player) and ending with the right-most one. During battle, attacker and blocker simultaneously deal damage to each other depending on their respective AP, given on the lower left of the card. </p>
        <p>When a Unit is damaged, it accrues Wounds equal to the amount of damage taken. When the number of Wounds is equal or greater than a Unit’s HP, given on the lower right of the card, it dies. Dead Units are put into Trash. </p>
        <p>Units may die from Wounds before the next Battle instance is initiated. Effects, for example triggered by Units dying, are given a chance to resolve before moving on to the next battle. In general, effects that are triggered during one Battle instance are resolved before moving on to the next. </p>
        <p>Attacking Units that are not blocked deal damage equal to their AP directly to the defending player. They lose Souls equal to the amount of damage received.</p>

        <h2 className={"header"}>Ending a Match</h2>
        <p>A match of Eigendark ends when a player wins or all but one player have lost.</p>
        <p>A player loses when they have zero Souls or Deck Cards.</p>
        <p>A player wins when all their enemies have lost.</p>
        <p>If all remaining players lose or win simultaneously, the match ends in a draw.</p>
    
      </section>
      </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Rules;

