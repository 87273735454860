import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as s from "./styles/globalStyles";
import Footer from './Footer';
import './styles/content.css';
import EbayListing from './EbayListing';
import { Link } from 'react-router-dom';

const bg = '/bg/wide_bg_dark.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ListingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CheckoutButton = styled.a`
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  margin-left: 20px;

  &:hover {
    background-color: #218838;
  }
`;

const Core = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Background>
        <div className={"container"}>
          <Content>
            <h2 className={"header"}>Print at home</h2>
            <p>Choose your&nbsp;<Link to="/factions" style={{ color: '#DAA520' }}>Science!</Link></p>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}> {/* Wrap the links in a flex container */}
              <div>
                <p> 🤖 <a href="https://drive.google.com/file/d/1kHQHU_VyZfc64uqPWkz3SSB-kcVk-ZZ8/view?usp=sharing" style={{ color: '#DAA520' }}>Glitch</a></p> 
                <p> 🪐 <a href="https://drive.google.com/file/d/1RK5WgcKqQzMLy2d3lK4LRFzUZe2CjH4k/view?usp=sharing" style={{ color: '#DAA520' }}>Gravity</a></p> 
              </div>
              <div> 
                <p> ⚛️ <a href="https://drive.google.com/file/d/181tLBq83wYAgu1OHailmAHz2f-JfPy6K/view?usp=sharing" style={{ color: '#DAA520' }}>Quantum</a></p> 
                <p> ☢️ <a href="https://drive.google.com/file/d/1NoD9ksUkfegwIrZMSvQ4zPTRTX5eLN3j/view?usp=sharing" style={{ color: '#DAA520' }}>Atomic</a></p> 
              </div>
              <div>
                <p> 🌎 <a href="https://drive.google.com/file/d/1sGKoKaci_THIjXOLi-YAzZ6VY2Qc10t3/view?usp=sharing" style={{ color: '#DAA520' }}>Planet</a></p> 
                <p> ⚗️ <a href="https://drive.google.com/file/d/1rQi29SZjN0gdI5PzQSyTLtZRLIWLDJHd/view?usp=sharing" style={{ color: '#DAA520' }}>Alchemy</a></p> 
              </div>
              <div>
                <p> 🦠 <a href="https://drive.google.com/file/d/1xMV_NvQHXXRPGLKqMXYTen07l0yOsY5y/view?usp=sharing" style={{ color: '#DAA520' }}>Biology</a></p> 
                <p> 🧠 <a href="https://drive.google.com/file/d/1M2_M6vE6d4dCO_M2MQMBJptdstvbjc11/view?usp=sharing" style={{ color: '#DAA520' }}>Psychic</a></p> 
              </div>
          </div>
            <s.SpacerMedium />
            <h2 className={"header"}>Official prints</h2>
            <div>
              {/* Display eBay and Stripe buttons in the same line */}
              <ListingContainer>
                <EbayListing
                  title="🧑 Single, eBay, $19.99"
                  description="+$9.99 shipping. 1 deck, 34 cards from any faction."
                  url="https://www.ebay.com/itm/134650408158"
                />
                OR
                <CheckoutButton href="https://buy.stripe.com/4gwdTu0BI7ms1vG3ce">-10% with Stripe</CheckoutButton>
              </ListingContainer>

              <ListingContainer>
                <EbayListing
                  title="🧑‍🤝‍🧑 Duo, eBay, $29.99"
                  description="+$9.99 shipping. 2 decks, 68 cards from 2 factions."
                  url="https://www.ebay.com/itm/134650431161"
                />
                OR
                <CheckoutButton href="https://buy.stripe.com/cN2cPq4RY36cfmwcMP">-20% with Stripe</CheckoutButton>
              </ListingContainer>

              <ListingContainer>
                <EbayListing
                  title="👨‍👩‍👧‍👦 Family, eBay, $99.99"
                  description="+$29.99 shipping. 8 decks, 272 cards (all factions)."
                  url="https://www.ebay.com/itm/134820734419"
                />
                OR
                <CheckoutButton href="https://buy.stripe.com/aEUcPq0BI228cakeUV">-20% with Stripe</CheckoutButton>
              </ListingContainer>
            </div>
          </Content>
        </div>
      </Background>
      <Footer />
    </>
  );
};

export default Core;
