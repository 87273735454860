import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import Footer from './Footer';

const Background = styled.div`
  background: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 16px;
  width: 90%;
  max-width: 1200px;
  user-select: text;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const OrdersTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

const TableHeader = styled.th`
  background-color: #444;
  padding: 12px;
  text-align: left;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TableCell = styled.td`
  padding: 12px;
  border-top: 1px solid #555;

  @media (max-width: 768px) {
    display: block;
    text-align: right;
    position: relative;
    padding-left: 50%;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    &:before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-weight: bold;
      text-align: left;
    }
  }
`;

const TableRow = styled.tr`
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 15px;
    border-bottom: 1px solid #555;
    padding: 10px 0;
  }
`;

const Button = styled.button`
  background: #DAA520;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #c69518;
  }
`;

const LoadingMessage = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
`;

const OrderDetailsModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  padding: 20px;
  border-radius: 2px;
  z-index: 1000;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  width: 33%;

  @media (max-width: 768px) {
    width: 80%;
  }

`;

const TrackingLink = styled.a`
  color: #DAA520;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  gap: 5px;
  margin-top: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const CancelButton = styled(Button)`
  background: #ff4136;
  margin-top: 10px;

  &:hover {
    background: #e60000;
  }
`;

const ErrorMessage = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
`;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //console.log("Current user:", currentUser);
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleCancelOrder = async (orderId) => {
    try {
        const orderRef = doc(db, 'orders', orderId);
        const orderDoc = await getDoc(orderRef); // Fetch the document
        if (!orderDoc.exists()) {
            throw new Error('Order not found');
        }
        const orderData = orderDoc.data(); // Get the document data

        await updateDoc(orderRef, { status: 'cancelled' });

        // Update the local state
        setOrders(prevOrders => 
            prevOrders.map(order => 
                order.id === orderId ? { ...order, status: 'cancelled' } : order
            )
        );

        if (selectedOrder && selectedOrder.id === orderId) {
            setSelectedOrder(prev => ({ ...prev, status: 'cancelled' }));
        }

        // Trigger email notification
        const response = await fetch('/api/notify-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderId: orderId,
                userId: user.uid,
                items: orderData.items,
                total: orderData.total,
                email: user.email,
                shippingAddress: orderData.shippingAddress,
                status: 'cancelled'
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to send notification');
        }

        alert('Order cancelled successfully');
    } catch (err) {
        console.error("Error cancelling order:", err);
        alert('Failed to cancel order. Please try again.');
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      if (!user) return;

      try {
        //console.log("Fetching orders for user:", user.uid);
        const ordersQuery = query(collection(db, 'orders'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(ordersQuery);
        const ordersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort orders by createdAt (latest first)
        ordersData.sort((a, b) => (b.createdAt.seconds - a.createdAt.seconds));

        //console.log("Orders data:", ordersData);
        setOrders(ordersData);
      } catch (err) {
        console.error("Error fetching orders:", err);
        if (err.code === 'permission-denied') {
          setError("Permission denied. Please check your account permissions.");
        } else {
          setError("Failed to fetch orders. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchOrders();
    }
  }, [user]);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp.seconds * 1000).toLocaleString();
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  if (loading) {
    return (
      <Background>
        <Content>
          <LoadingMessage>Loading...</LoadingMessage>
        </Content>
      </Background>
    );
  }

  if (!user) {
    return (
      <Background>
        <Content>
          <ErrorMessage>Please log in to view your orders.</ErrorMessage>
        </Content>
      </Background>
    );
  }

  if (error) {
    return (
      <Background>
        <Content>
          <ErrorMessage>{error}</ErrorMessage>
        </Content>
      </Background>
    );
  }

  return (
    <>
      <Background>
        <Content>
          <h2>Your Orders</h2>
          {orders.length === 0 ? (
            <p>You haven't placed any orders yet.</p>
          ) : (
            <OrdersTable>
              <thead>
                <tr>
                  <TableHeader>Order ID</TableHeader>
                  <TableHeader>Tracking</TableHeader>
                  <TableHeader>Date</TableHeader>
                  <TableHeader>Total</TableHeader>
                  <TableHeader>Status</TableHeader>
                  <TableHeader>Actions</TableHeader>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell data-label="Order ID">{order.id}</TableCell>
                    <TableCell data-label="Tracking">
                      {order.trackingNumber ? (
                        <TrackingLink href={`https://parcelsapp.com/en/tracking/${order.trackingNumber}`} target="_blank" rel="noopener noreferrer">
                          {order.trackingNumber}
                        </TrackingLink>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell data-label="Date">{formatDate(order.createdAt)}</TableCell>
                    <TableCell data-label="Total">{order.total.toFixed(2)} EUR</TableCell>
                    <TableCell data-label="Status">{order.status}</TableCell>
                    <TableCell data-label="Actions">
                      <Button onClick={() => handleViewDetails(order)}>View Details</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </OrdersTable>
          )}
        </Content>
      </Background>
      <Footer />

      {selectedOrder && (
        <>
          <Overlay onClick={closeModal} />
          <OrderDetailsModal>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            {/* 
            <h3>Order Details</h3>
            <p><strong>Order ID:</strong> {selectedOrder.id}</p>
            <p><strong>Date:</strong> {formatDate(selectedOrder.createdAt)}</p>
            <p><strong>Total:</strong> {selectedOrder.total.toFixed(2)} EUR</p>
            <p><strong>Status:</strong> {selectedOrder.status}</p>
            <h4>Shipping Address:</h4>
            <p>
              {selectedOrder.shippingAddress.firstName} {selectedOrder.shippingAddress.lastName}<br />
              {selectedOrder.shippingAddress.streetAddress}<br />
              {selectedOrder.shippingAddress.city}, {selectedOrder.shippingAddress.postalCode}<br />
              {selectedOrder.shippingAddress.country}
            </p>
            <h4>Items:</h4>
            */}
            <ItemsGrid>
              {selectedOrder.items.map((item, index) => (
                <ItemContainer key={index}>
                  <ItemImage src={item.card_id} alt={`Item ${index + 1}`} />
                </ItemContainer>
              ))}
            </ItemsGrid>
            {selectedOrder.status === 'paid' && (
              <CancelButton onClick={() => handleCancelOrder(selectedOrder.id)}>
                Cancel Order
              </CancelButton>
            )}
          </OrderDetailsModal>
        </>
      )}
    </>
  );
};

export default Orders;