import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_gl2.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  margin-right: 15px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Glitch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/glitch/glitch_faction0.jpg`} alt="Glitch Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/glitch/glitch_faction0.jpg`} alt="Glitch Magic" />
        <div>
          <h2 className={"header"}>G̵͍͐ḻ̷̍i̸̻͒t̴͇̎c̶̞̔h̵̹̔ ̸̤̏M̵̯̀a̷͙͆g̸̫̔i̵̤̓c̵̟̽</h2>
          <p>As a member of the Glitch Faction, your playground is the grand simulation of reality. By prompting your AI familiar, you gain access to the console of life. Codes are your spells, glitches your weapons. Your gameplay is all about intel, laws and taxes - only for your opponent, of course. In the Glitch-Mages’ world, turbo-capitalism reigns supreme as the ultimate game for power. Kafkaesque hierarchies and labyrinthine rules will encrypt and erase all efforts for your opponent. 
          Glitch is playing aggressively with proactive control. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/glitch/glitch_faction1.jpg`} alt="Glitch Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/glitch/glitch_faction1.jpg`} alt="Glitch Magic" />
        <div>
          <p>
          A Glitch-Mage’s appearance is ever-changing, shifting between the physical and the virtual, their form clad in a swirling cloak of glitchy runes. Some may prefer the skintight suits of Cyber-Runners, others augment themselves with bionic synthetics until they are beyond all recognition.
          <br />
          Victory for a Glitch Mage is a well-executed exploitation; defeat is but a system crash away from a reboot and return to the game.
          <br />
          Glitch Mages traverse the universe vertically, into different layers of computation. They form mega-conglomerates so convoluted that not even their most proficient bureaucrats may unravel their agendas. To them, reality is malleable, and the line between the user and the avatar, the real and the virtual, blurs into irrelevance. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/glitch/glitch_faction2.jpg`} alt="Glitch Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/glitch/glitch_faction2.jpg`} alt="Glitch Magic" />
        <div>
          <p>
          Amongst the Glitch Codex, there is "The Company," an incredibly resourceful entity with obscure methods and infinite hierarchies. It is said to spread like an elevator across dozens, if not hundreds of multiverses. Their goals are elusive, yet they are considered the most potent and subtle force everywhere they’re present. 
          <br />
          Drawn into the Glitch are hackers, gamers, social media gurus, or even IT-consultants.
          <br />
          The Glitch deck is a treasure trove for players who enjoy disrupting conventional gameplay. Each card presents a fresh opportunity to manipulate the game in surprising ways. The intricate, cyberpunk-inspired aesthetics will appeal to those interested in computers and sci-fi.
          <br />
          Your potential as a Glitch Mage is boundless. Take the step. Pull the rug on reality.
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/create" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Glitch;

