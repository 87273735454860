import React, { useEffect }  from 'react';
import styled from 'styled-components';
import * as s from "./styles/globalStyles";
import Footer from './Footer';
import { Link } from 'react-router-dom';
import './styles/content.css';

const bg = '/bg/wide_bg_clean3.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
      <Content>      
      <h2 className={"header"}>Disclaimer</h2>
      <p><h2 className={"question"}>1. Liability for contents</h2>
As a service provider, we are responsible for our own content on these pages in accordance with Section 7 (1) TMG (German Telemedia Act) and general legislation. According to §§ 8 to 10 TMG, however, we as a service provider are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general legislation remain unaffected by this. However, liability in this respect is only possible from the time of knowledge of a specific infringement. As soon as we become aware of such infringements, we will remove this content immediately.
      </p>
      <p><h2 className={"question"}>2. Liability for links</h2>
Our website contains links to external third-party websites over whose content we have no influence. Therefore, we cannot accept any liability for this third-party content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal content was not recognizable at the time of linking. However, permanent monitoring of the content of the linked pages is not reasonable without concrete evidence of an infringement. If we become aware of any legal infringements, we will remove such links immediately.
      </p>
      <p><h2 className={"question"}>3. Privacy</h2></p>
      <p>&nbsp;<Link to="/privacy-policy" style={{ color: '#DAA520' }}>Privacy-Policy</Link></p>
      <p><h2 className={"question"}>4. Terms & Conditions</h2></p>
      <p>&nbsp;<Link to="/terms" style={{ color: '#DAA520' }}>Terms & Conditions</Link></p>
      <br />
      <h2 className={"header"}>Impressum</h2>
      <p>K. Libicher<br />
      Philipp-Matthäus-Hahn Str. 16<br />
      73760 Ostfildern, BW, Germany<br />
      eigendark@gmail.com</p>
      </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Impressum;
