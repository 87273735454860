import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_al2.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  max-width: 256px;
  margin-right: 15px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Alchemy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction0.jpg`} alt="Alchemy Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction0.jpg`} alt="Alchemy Magic" />
        <div>
          <h2 className={"header"}>Alchemy Magic ⚝</h2>
          <p>The Alchemy House is a sanctuary for those intrigued by the art of metamorphosis and the enigmatic process of transmutation.
            Guided by their AI familiars in animal-form, these masters of transformation tirelessly uncover the emerald secrets of the Philosopher's Stone, the symbol of their quest for ultimate transformation. They turn the battlefield into a laboratory of alchemical miracles.            
            The Alchemist's playstyle is one of reactive control, with a strong affinity for complicated combos and synergies. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction1.jpg`} alt="Alchemy Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction1.jpg`} alt="Alchemy Magic" />
        <div>
          <p>
          Step into the shoes of the Alchemical Mastermind. Clad in mysterious robes adorned with occult symbols, these wise sages observe the balance of elements with their keen and calculating eyes. Their background varies, from chemists and biologists to cooks and brewers, each obsessed with the process of transformation before being drawn to the alchemical arts. Their intimate knowledge of how different components interact forms the heart of the Alchemy faction's art.          <br />
          An Alchemist's victory is the successful culmination of a complex recipe, and their defeat merely an experiment gone awry, a stepping stone on the path to success. To face them is to anticipate a sudden, shocking revelation that leaves their enemies, if they still possess the capacity to think, questioning the very fabric of their understanding.          
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction2.jpg`} alt="Alchemy Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction2.jpg`} alt="Alchemy Magic" />
        <div>
          <p>
          The Alchemy House seeks to be the chemists, pharmacists and teachers of an alchemical universe, where the principles of change and balance govern all existence.
          <br />
          The Alchemy Deck, with its stunning card art depicting various alchemical processes and symbols, is a strategist's dream. Designed for those who enjoy setting up powerful combinations, the deck embodies the idea of transformation, offering cards that can transmute into more potent forms or alter the nature of other cards. An Alchemist's magnum opus may seem sudden to an outsider, but to them, it is the fruition of a complex recipe, a testament to their careful planning and meticulous preparation.
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/create" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Alchemy;

