// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";
import { DISCONNECT } from "./blockchainActionTypes";

const errorMessageStyle = {
  color: 'red',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '10px',
  //border: '1px solid red',
  //borderRadius: '5px',
  whiteSpace: 'nowrap', // Add this property to prevent line breaks
};

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

export const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi_eth.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config_eth.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);

      const handleNetworkChange = async () => {
        const currentNetworkId = await ethereum.request({
          method: "net_version",
        });
      
        if (currentNetworkId == CONFIG.NETWORK.ID) {
          dispatch(connect());
        } else {
          const errorMessage = (
            <span style={errorMessageStyle}>Change network to {CONFIG.NETWORK.NAME}.</span>
          );
          dispatch(connectFailed(errorMessage));
        }
      };
      

      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", handleNetworkChange);
          // Add listeners end
        } else {
          // Create an HTML element with the desired inline style
          const errorMessage = (
            <span style={errorMessageStyle}>Change network to {CONFIG.NETWORK.NAME}.</span>
          );
          dispatch(connectFailed(errorMessage));
          // Add listener for chainChanged event
          ethereum.on("chainChanged", handleNetworkChange);
        }            
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};


export const disconnect = () => ({
  type: DISCONNECT,
});
