import React, { useEffect, useState } from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Footer from './Footer';
const bg = './bg/bg_al.png';
import Reprint from './Reprint';
import Rename from './Rename';
import Flavor from './Flavor';
import Craft from './Craft';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 1025px) {
    min-height: 95vh;
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start; 
  justify-content: space-around;
  width: 100%;
  height: auto;  
  `;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  width: 200px;
  opacity: 0.75;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
  @media (max-width: 1024px) {
    display: None;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const StyledContainer = styled(s.Container)`
  background-color: rgba(0,0,0,0.5);
  padding: 24px;
  border-radius: 24px;
  width: auto;
  box-shadow: 0px 5px 11px 2px rgba(0,0,0,0.7);
  align-items: center;

  @media (min-width: 768px){
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const Crafting = () => {

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 0,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_FEE: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  let cost = CONFIG.WEI_COST;
  let gasLimit = CONFIG.GAS_LIMIT;
  let totalCostWei = String(cost * mintAmount);
  let totalGasLimit = String(gasLimit * mintAmount);
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <s.Screen>
      <Background>
      <s.SpacerXL />
        <TileContainer>
          <div className="tile"><Reprint /></div>
          <div className="tile"><Rename /></div>
          <div className="tile"><Flavor /></div>
          <div className="tile"><Craft /></div>
        </TileContainer>
      <s.SpacerLarge />
      <StyledContainer>
        <h2 className={"question"} style={{ margin: 0, color: 'var(--secondary)' }}>{CONFIG.DISPLAY_FEE}{" "}{CONFIG.NETWORK.SYMBOL} / Request</h2>
      </StyledContainer>
      </Background>
      <Footer />
    </s.Screen>
  );
}


export default Crafting;