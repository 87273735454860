import React from 'react';
import './styles/privacy.css';
import styled from 'styled-components';
import Footer from './Footer';

const bg = '/bg/bg5.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 16px;
  width: 90%;
  max-width: 1200px;
  user-select: text;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const PrivacyPolicy = () => {
  return (
    <>
    <Background>
      <div className={"container"}>
      <Content>
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: [20.09.2024]</p>

      <h2>1. Introduction</h2>
      <p>Welcome to Eigendark ("we", "our", or "us"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>

      <h2>2. Information We Collect</h2>
      <p>We collect information that you provide directly to us when you use our website. We also collect certain information automatically when you visit our website.</p>

      <h3>2.1 Information You Provide to Us</h3>
      <ul>
        <li>Account information: When you create an account, we collect your name and email address.</li>
        <li>User content: Any content you choose to upload or post on our website.</li>
      </ul>

      <h3>2.2 Information We Collect Automatically</h3>
      <p>We use cookies and similar tracking technologies to collect anonymous information about user interactions with our website (Google Analytics). This includes:</p>
      <ul>
        <li>Usage information: Pages viewed, time spent on pages, click-through patterns.</li>
        <li>Device information: IP address, browser type, operating system.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide and maintain our website</li>
        <li>To improve our website and user experience</li>
        <li>To communicate with you about updates or respond to inquiries</li>
        <li>To monitor and analyze usage patterns and trends</li>
      </ul>

      <h2>4. Cookies</h2>
      <p>We use the following cookies:</p>
      <ul>
        <li><strong>_ga</strong>: This Google Analytics cookie is used to distinguish users and has an expiration of 2 years.</li>
        <li><strong>_ga****VDB8CL92EG</strong>: This Google Analytics cookie is used to maintain session state and analyze website performance.</li>
      </ul>
      <p>These cookies collect anonymous data about user behavior on our website. We use this information to improve our services and understand how visitors use our site.</p>

      <h2>5. Your Rights</h2>
      <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
      <ul>
        <li>The right to access the personal information we hold about you</li>
        <li>The right to request the correction of inaccurate personal information</li>
        <li>The right to request deletion of your personal information</li>
        <li>The right to opt-out of certain data collection and use</li>
      </ul>

      <h2>6. Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>

      <h2>7. Third-Party Links</h2>
      <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at [Your Contact Email].</p>
    </div>
    </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;