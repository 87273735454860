import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function EbayListing({ title, description, url }) {
    const [hover, setHover] = useState(false);  // Define hover state

    return (
        <Card style={{margin: '20px', backgroundColor: 'rgba(66, 66, 66, 0.5)'}}>
            <CardContent>
                <Typography variant="h5" component="h2" style={{color: '#E0E0E0'}}>
                    {title}
                </Typography>
                <Typography variant="body2" component="p" style={{color: '#BDBDBD'}}>
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                <Box display="flex" justifyContent="center" width="100%">
                    <Button
                        size="small"
                        variant="outlined"
                        style={{
                            borderColor: hover ? 'black' : 'yellow',
                            color: hover ? 'black' : 'yellow',
                            backgroundColor: hover ? 'yellow' : 'transparent',
                            transition: '0.3s',
                        }}
                        onClick={() => { window.open(url, "_blank") }}
                        onMouseEnter={() => setHover(true)}  // On hover, set the state to true
                        onMouseLeave={() => setHover(false)}  // On mouse leave, set the state to false
                    >
                        View
                    </Button>
                </Box>
            </CardActions>
        </Card>
    );
}

export default EbayListing;
