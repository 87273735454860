import React, { useEffect }  from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Footer from './Footer';
import LazyLoad from 'react-lazyload';
const bg = './bg/bgx.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  width: 256px;
  margin-right: 15px;
  @media (min-width: 1025px) {
    margin-top: 20px; /* Adjust this value as per your requirements */
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TrackingLink = styled.a`
  color: #DAA520;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Rules = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <LazyLoad>
      <section className={"content"}>
      <p>For faction game mechanics, read our <TrackingLink href="https://www.reddit.com/r/eigendark/comments/1c1crhn/design_guide_for_prompt_wizards_and_aspiring_ones/" target="_blank" rel="noopener noreferrer">Subreddit</TrackingLink></p>
      <Link to="/glitch" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/glitch_lab.png`} alt="Glitch" /></Link>
      <FactionContainer>
      <Link to="/glitch" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/glitch_lab.png`} alt="Glitch" /></Link>
        <div>
          <h2 className={"header"}>🤖 Glitch</h2>
          {/*<p><Link to="/glitch" style={{ color: '#DAA520' }}>
            Enter
            </Link> the machine bureaucracy in a corrupted matrix of realities.
          </p>*/}
          <p>
            <strong>Playstyle</strong>: Politics, Ambush, Sabotage<br/>
            <strong>Home to:</strong> Bureaucrats, Hackers, Machines<br/>
            {/*<strong>Examples:</strong> Async Inc., the Virtual Syndicate<br/>
            <strong>Locations:</strong> Silicon Valley Anomaly Zone, Neo-Kyoto, Electro-Rome<br/>
            <strong>People:</strong> Ada Lovelace. Sir Alan Turing. Father von Neumann.<br/>
            <strong>Enemies:</strong> Psychic, Biology*/}
            <strong>Domains:</strong> Tech, Finance, Law, Politics<br/>
            <strong>Elements:</strong> Paper, Silicon, Digital, Plastic<br/>
            <strong>Realms:</strong> Cities, Markets, Metaverse<br/>
            <strong>Values:</strong> Wealth, Information, Tech<br/>
            <br/>
          </p>
        </div>
      </FactionContainer>
      <Link to="/gravity" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/gravity_lab.png`} alt="Gravity" /></Link>
      <FactionContainer>
      <Link to="/gravity" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/gravity_lab.png`} alt="Gravity" /></Link>
        <div>
        <h2 className={"header"}>🪐 Gravity</h2>
        {/*<p><Link to="/gravity" style={{ color: '#DAA520' }}>Bend </Link> the fabric of space-time to cosmic order amongst the stars.
        </p>*/}
          <p>
            <strong>Playstyle</strong>: Control, Lategame, Stagnation<br/>
            <strong>Home to:</strong> Astronomers, Architects, Angels<br/>
            {/*<strong>Examples:</strong> Void Ecclesiarchy, the Arcane Order.<br/>
            <strong>Locations:</strong> Heavens, Space, frozen Antarctica.<br/>
          <strong>People:</strong> Lord Newton. Einstein. Czar of endless Night.<br/>
          <strong>Enemies:</strong> Quantum*/}
            <strong>Domains:</strong> Astronomy, Topology, Aerospace<br/>
            <strong>Elements:</strong> Dark, Cosmic, Frost<br/>
            <strong>Realms:</strong> Space, Empyrean, Abyss<br/>
            <strong>Values:</strong> Order, Stillness, Logic<br/>
            <br/>
          </p>
        </div>
      </FactionContainer>
      <Link to="/quantum" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/quantum_lab.png`} alt="Quantum" /></Link>
      <FactionContainer>
      <Link to="/quantum" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/quantum_lab.png`} alt="Quantum" /></Link>
        <div>
        <h2 className={"header"}>⚛️ Quantum</h2>
         {/* <p><Link to="/quantum" style={{ color: '#DAA520' }}>Slip </Link> into the quantum foam of reality and seize primordial chaos.
        </p>*/}
          <p>
            <strong>Playstyle</strong>: Trickery, Randomness, Storm<br/>
            <strong>Home to:</strong> Artists, Inventors, Demons<br/>
            {/*<strong>Examples:</strong> Empyrean Emirate, the Fractal Punks. <br/>
            <strong>Locations:</strong> CERN, Maw of Chaos, Planck's Abyss.<br/>
        <strong>People:</strong> Schrödinger. Warlock Maxwell. <br/>
        <strong>Enemies:</strong> Gravity*/}
            <strong>Domains:</strong> Causality, Entropy, Quantum<br/>
            <strong>Elements:</strong> Light, Chaos, Energy<br/>
            <strong>Realms:</strong> Void, Nano, Multiverse<br/>
            <strong>Values:</strong> Freedom, Creativity, Entropy<br/>
            <br/>
          </p>
        </div>
      </FactionContainer>
      <Link to="/atomic" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/atomic_lab.png`} alt="Atomic" /></Link>
      <FactionContainer>
      <Link to="/atomic" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/atomic_lab.png`} alt="Atomic" /></Link>
        <div>
        <h2 className={"header"}>☢️ Atomic</h2>
        {/*  <p><Link to="/atomic" style={{ color: '#DAA520' }}>Crush </Link> your foes with military might and industrial superiority! 
        </p>*/}
          <p>
            <strong>Playstyle</strong>: Aggro, Reckless, Equipments<br/>
            <strong>Home to:</strong> Mechas, Engineers, Mutants<br/>
            {/*<strong>Examples:</strong> The Radiant Papacy, Electromancer's Guild<br/>
            <strong>Locations:</strong> Factories, Wastes, Contamination Zones<br/>
      <strong>People:</strong>  Madame Curie. Oppenheimer. Nikola Tesla. <br/>
      <strong>Enemies:</strong> Planet, Alchemy*/}
            <strong>Domains:</strong> Electromagnetism, Engineering<br/>
            <strong>Elements:</strong> Lightning, Metal, Oil, Plasma<br/>
            <strong>Realms:</strong> Industry, Wastes, Trenches<br/>
            <strong>Values:</strong> Power, Ruthlessness, Heroism<br/>
            <br/>
          </p>
        </div>
      </FactionContainer>
      <Link to="/planet" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/planet_lab.png`} alt="Planet" /></Link>
      <FactionContainer>
      <Link to="/planet" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/planet_lab.png`} alt="Planet" /></Link>
        <div>
        <h2 className={"header"}>🌎 Planet</h2>
        {/* <p><Link to="/planet" style={{ color: '#DAA520' }}>Heed </Link> your planet's call and protect the natural balance!
        </p>*/}
          <p>
            <strong>Playstyle</strong>: Grouphug, Midrange, Ramp<br/>
            <strong>Home to:</strong> Druids, Shamans, Elementals<br/>
            {/*<strong>Examples:</strong> The Leyline Network, Gaia's Guardians. <br/>
            <strong>Locations:</strong> Mountains, Seas, Forests, Plains.<br/>
    <strong>People:</strong> Venerable Wegener, Prof. Hopper<br/>
    <strong>Enemies:</strong> Atomic, Alchemy*/}
            <strong>Domains:</strong> Geology, Climate, Agriculture<br/>
            <strong>Elements:</strong> Earth, Fire, Water, Wind<br/>
            <strong>Realms:</strong> Mountains, Seas, Forests, Plains<br/>
            <strong>Values:</strong> Harmony, Nature, Community<br/>
            <br/>
          </p>
        </div>
      </FactionContainer>
      <Link to="/alchemy" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/alchemy_lab.png`} alt="Alchemy" /></Link>
      <FactionContainer>
      <Link to="/alchemy" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/alchemy_lab.png`} alt="Alchemy" /></Link>
        <div>
        <h2 className={"header"}>⚗️ Alchemy</h2>
         {/* <p><Link to="/alchemy" style={{ color: '#DAA520' }}>Join </Link> the House of Alchemists and shape the world to your image!
        </p>*/}
          <p>
            <strong>Playstyle</strong>: Combo, Synergy, Toolbox<br/>
            <strong>Home to:</strong> Witches, Chemists, Doctors<br/>
            {/*<strong>Examples:</strong> Golden Caliphate. Ethereal Academy. <br/>
            <strong>Locations:</strong> Academies, Caves, Temples, hollow Earth. <br/>
  <strong>People:</strong> Jabir ibn Hayyan the Alchemist, Paracelsus the Healer<br/>
  <strong>Enemies:</strong> Atomic, Planet*/}
            <strong>Domains:</strong> Chemistry, Medicine, Archeology<br/>
            <strong>Elements:</strong> Gas, Crystal, Poison, Gold<br/>
            <strong>Realms:</strong> Caves, Ruins, Islands<br/>
            <strong>Values:</strong> Perfection, Truth, Transcendence<br/>
            <br/>
          </p>
       </div>
      </FactionContainer>
      <Link to="/life" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/life_lab.png`} alt="Life" /></Link>
      <FactionContainer>
      <Link to="/life" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/life_lab.png`} alt="Life" /></Link>
        <div>
          <h2 className={"header"}>🦠 Biology</h2>
          {/*<p><Link to="/life" style={{ color: '#DAA520' }}>Be</Link> consumed by the Thirst for Life and shed your feeble shell.
        </p>*/}
          <p>
            <strong>Playstyle</strong>: Swarm, Recursion, Value<br/>
            <strong>Home to:</strong> Wildlife, Plants, Undead<br/>
            {/*<strong>Examples:</strong> Herbarium of Shadows. Draconic Matriarchate. <br/>
            <strong>Locations:</strong> Forests, Swamps, Catacombs, Hives, Sewers<br/>
<strong>People:</strong> Rosalind Franklin, Watson & Crick, Darwin, Mendel.<br/>
<strong>Enemies:</strong> Psychic, Glitch*/}
            <strong>Domains:</strong> Genetics, Life Science, Necromancy<br/>
            <strong>Elements:</strong> Wood, Flesh, Blood, Bone<br/>
            <strong>Realms:</strong> Jungle, Sewers, Swamps<br/>
            <strong>Values:</strong> Darwinism, Communism, Instinct<br/>
            <br/>
          </p>
        </div>
      </FactionContainer>
      <Link to="/psycho" ><StyledLogo src={`${process.env.PUBLIC_URL}/lab/psycho_lab.png`} alt="Psycho" /></Link>
      <FactionContainer>
      <Link to="/psycho" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/lab/psycho_lab.png`} alt="Psycho" /></Link>
        <div>
        <h2 className={"header"}>🧠 Psychic</h2>
         {/* <p><Link to="/psycho" style={{ color: '#DAA520' }}>Come</Link> home to the Spiritworld, a place for the seekers and the lost.
        </p>*/}
          <p>
            <strong>Playstyle</strong>: Mill, Manipulation, Confusion<br/>
            <strong>Home to:</strong> Prophets, Psychics, Spirits<br/>
            {/*<strong>Examples:</strong> The Eldritch Theocracy. The Cult of Mentis. <br/>
            <strong>Locations:</strong> Castles, Coasts, Liminal Spaces, Dreams, Spiritworld.<br/>
<strong>People:</strong> Plato. Freud. Pavlov. Edgar Allan Poe.<br/>
<strong>Enemies:</strong> Glitch, Biology*/}
            <strong>Domains:</strong> Psychology, Philosophy, Neurology<br/>
            <strong>Elements:</strong> Mind, Spirit, Soul, Shadow<br/>
            <strong>Realms:</strong> Coastlines, Villages, Dreams<br/>
            <strong>Values:</strong> Faith, Emotion, Ego<br/>
            <br/>
          </p>
        </div>
      </FactionContainer>
       </section>
       </LazyLoad>
      </Content>
      </ContentWrapper>
    </div>

    </Background>
    <Footer />
    </>
  );
};

export default Rules;

