import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as s from "./styles/globalStyles";
import Footer from './Footer';
import './styles/content.css';
import EbayListing from './EbayListing';

const bg = '/bg/wide_bg_dark2.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ListingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TrackingLink = styled.a`
  color: #DAA520;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Core = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Background>
        <div className={"container"}>
          <Content>
            <h2 className={"title"}>Receive monthly cards</h2>
            <div>
              <p>Subscribe & worry no more about missing the latest cards! Get a monthly pack via post!</p>
              <p>Players create new cards designated to the awake zodiac, before it falls into a 12-month slumber.<br></br>
              When this happens (ca. 20th day of a month), you will receive the top cards as voted on our&nbsp;<TrackingLink href="https://www.reddit.com/r/eigendark" target="_blank" rel="noopener noreferrer">Subreddit</TrackingLink></p>
              <ListingContainer>
                <EbayListing
                  title="⭐ Zodiac, €19.99"
                  description="Each month the top 15 cards +3 specials exclusive to subscribers only."
                  url="https://buy.stripe.com/cN2g1C84abCIeis8wA"
                />

                <EbayListing
                  title="🔮 Big Zodiac, €29.99"
                  description="Same as Zodiac plus comes Packaged and Sealed in an official Booster Pack."
                  url="https://buy.stripe.com/7sIbLm3NUeOU8Y86ou"
                />
              </ListingContainer>
            </div>
          </Content>
        </div>
      </Background>
      <Footer />
    </>
  );
};

export default Core;
