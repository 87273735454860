import React from 'react';
import './styles/privacy.css';
import styled from 'styled-components';
import Footer from './Footer';

const bg = '/bg/tall_bg_red2.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 16px;
  width: 90%;
  max-width: 1200px;
  user-select: text;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Terms = () => {
  return (
    <>
    <Background>
      <div className={"container"}>
      <Content>
    <div className="privacy-policy">
      <h1>Terms & Conditions</h1>
      <p>Last updated: [20.09.2024]</p>

      <h2>1. Introduction</h2>
      <p>Welcome to Eigendark ("we", "our", "us", or the "Company"). These Terms & Conditions govern your use of our website and services, including the creation, publication, and sale of trading cards generated with our services.</p>

      <h2>2. Acceptance of Terms</h2>
      <p>By accessing or using our website, you agree to be bound by these Terms & Conditions. If you disagree with any part of these terms, you may not use our services.</p>

      <h2>3. User Accounts</h2>
      <p>To access certain features of the website, you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

      <h2>4. Creation and Publication of Trading Cards</h2>
      <p>4.1. Our website allows users to create trading cards using AI-generated images.</p>
      <p>4.2. By creating and publishing a trading card on our platform, you grant Eigendark an irrevocable, worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, perform, and sell the trading card in connection with the Service and Eigendark's business.</p>
      <p>4.3. You acknowledge and agree that Eigendark retains all profits from the sale of trading cards on the platform.</p>
      <p>4.4. You represent and warrant that you have all necessary rights to the content you use to create trading cards and that the creation and publication of your trading cards do not violate any third-party rights.</p>
      <p>4.5. If you use or derive content from public domain sources or sources licensed under CC-BY-SA, you agree to comply with all applicable license terms and provide appropriate attribution where required.</p>

      <h2>5. Use of Public Domain and CC-BY-SA Content</h2>
      <p>5.1. You may use or derive content from public domain sources or sources licensed under Creative Commons Attribution-ShareAlike (CC-BY-SA), such as certain SCP Foundation content, in creating your trading cards. Our software handles automatic attribution for your convenience, however you are responsible to ensure the attribution is correct.</p>
      <p>5.2. When using CC-BY-SA licensed content, you must:</p>
      <ul>
        <li>Provide appropriate credit to the original creator(s)</li>
        <li>Indicate if changes were made to the original content</li>
        <li>Distribute your contributions under the same license as the original</li>
      </ul>
      <p>5.3. You acknowledge that by using CC-BY-SA content in your trading cards, those specific cards and their derivatives may be subject to the CC-BY-SA license terms.</p>
      <p>5.4. Eigendark reserves the right to provide the necessary attributions and license information for trading cards that incorporate CC-BY-SA content.</p>

      <h2>6. Intellectual Property</h2>
      <p>6.1. You retain ownership of the intellectual property rights for the original content you create, subject to the license granted to Eigendark in Section 4.2.</p>
      <p>6.2. For content derived from public domain or CC-BY-SA sources, the respective license terms or public domain status apply.</p>

      <h2>7. Sale of Trading Cards</h2>
      <p>7.1. By publishing a trading card to the public gallery, you authorize Eigendark to sell your trading cards to other users.</p>
      <p>7.2. Eigendark retains the right to set the price for all trading cards sold on the platform.</p>
      <p>7.3. You acknowledge and agree that Eigendark retains all profits from the sale of trading cards on the platform.</p>

      <h2>8. Prohibited Activities</h2>
      <p>You agree not to engage in any of the following activities:</p>
      <ul>
        <li>Violating any applicable laws or regulations</li>
        <li>Infringing on the intellectual property rights of others</li>
        <li>Creating or publishing offensive, harmful, or illegal content</li>
        <li>Attempting to interfere with the proper functioning of the Service</li>
        <li>Misrepresenting the source or licensing terms of content used in trading cards</li>
      </ul>

      <h2>9. Termination</h2>
      <p>We reserve the right to terminate or suspend your account and access to the Service at our sole discretion, without notice, for any reason, including breach of these Terms & Conditions.</p>

      <h2>10. Limitation of Liability</h2>
      <p>To the maximum extent permitted by law, Eigendark shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of our Service.</p>

      <h2>11. Changes to Terms & Conditions</h2>
      <p>We reserve the right to modify these Terms & Conditions at any time. We will provide notice of significant changes by posting an announcement on our website. Your continued use of the Service after such modifications constitutes your acceptance of the updated Terms & Conditions.</p>

      <h2>12. Governing Law</h2>
      <p>These Terms & Conditions shall be governed by and construed in accordance with the laws of Germany, without regard to its conflict of law provisions.</p>

      <h2>13. Contact Us</h2>
      <p>If you have any questions about these Terms & Conditions, please contact us at eigendark@gmail.com.</p>
    </div>
    </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Terms;