import React, { useState, useEffect } from 'react';

const Cookie = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!hasAcceptedCookies) {
      setShowDisclaimer(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowDisclaimer(false);
  };

  if (!showDisclaimer) return null;

  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'black',
      padding: '10px',
      textAlign: 'center',
      zIndex: 1000,
      color: 'white',
    }}>
      <p>
        This website uses cookies to improve your browsing experience and analyze site traffic. 
        By clicking "Accept" or continuing to use our site, you consent to our use of cookies.
      </p>
      <button onClick={handleAccept} style={{
        backgroundColor: 'white',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px', // Reduced padding
        fontSize: '14px', // Adjust font size
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
      }}>
        Accept
      </button>
      <a href="/privacy-policy" style={{
        marginLeft: '10px',
        color: 'white',
        textDecoration: 'underline'
      }}>
        Learn More
      </a>
    </div>
  );
};

export default Cookie;
