import React, { useEffect }  from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_qu2.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  margin-right: 15px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Quantum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/quantum/quantum_faction0.jpg`} alt="Quantum Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/quantum/quantum_faction0.jpg`} alt="Quantum Magic" />
        <div>
          <h2 className={"header"}>Quantum Magic ⚛</h2>
          <p>Quantum Mages don't just journey into space-time; they delve into its innermost depths. They masterfully navigate countless subatomic dimensions and virtual emanations. These powerful spellslingers and aggressive tricksters harness their powers from the quantum foam of baseline reality, bending causality to their will.
          They seek optimal timelines and universes for resource gathering, colonization, or evading potential threats. 
          Quantum is a very active playstyle, you will dictate the pace of the game with agression and combo. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/quantum/quantum_faction1.jpg`} alt="Quantum Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/quantum/quantum_faction1.jpg`} alt="Quantum Magic" />
        <div>
          <p>
          Quantum Wayfarers are enigmatic figures often appearing slightly out of sync with reality, as if they're partially present in multiple timelines. Cloaked in iridescent garments that shimmer and change as they move, they embody the flux of the quantum particles they manipulate.
          <br />
          Fashioned from the probabilistic waves of quantum reality, each Quantum Mage’s AI familiar embodies an otherworldly elegance. They shimmer and shift with a hypnotic iridescence, reflecting the multiverse's infinite possibilities. Their forms, though not constant, often resemble complex geometric patterns, like an ever-evolving fractal caught in the dance of creation and destruction.
          <br />
          Quantum Mages dream of a universe devoid of impossibility, yearning for a world where any course of action can be pursued without consequence.           
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/quantum/quantum_faction2.jpg`} alt="Quantum Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/quantum/quantum_faction2.jpg`} alt="Quantum Magic" />
        <div>
          <p>
          Former quantum physicists, mathematicians and data miners often find themselves among the Quantum Wayfarers. Even gamblers, stock market traders, or professional athletes who deal with uncertainties and probabilities in their respective fields might discover an uncanny kinship with the Quantum Way. 
          <br />
          They leap across timelines, exploiting quantum mechanics' quirks to outsmart causality. To them, death is merely a quantum probability. They exist everywhere and nowhere, transcending space and time while casting an array of spells simultaneously. 
          <br />
          The Quantum Deck keeps opponents second-guessing, offering multiple potential outcomes with each play. Unique mechanics may include cards that exist in several states until played or that evade fate in the last second. The Quantum Deck is a must-have for those with joy for infinite possibilities.
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/create" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Quantum;

