import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_at.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  max-width: 256px;
  margin-right: 15px;
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Atomic = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/atomic/atomic_faction0.jpg`} alt="Atomic Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/atomic/atomic_faction0.jpg`} alt="Atomic Magic" />
        <div>
          <h2 className={"header"}>Atomic Magic ☢</h2>
          <p>Embarking on the Atomic Path means harnessing and commanding all forms of energy and matter, particularly the transition between the two. Clad in armor spun from unfathomably potent materials, an Atomic Mage mirrors the visage of a radioactive mutant. 
            As an Atomic Mage, you'll have your AI construct at your behest, manipulating the holy Trinity of forces: electromagnetism, the strong and the weak interaction.
            You'll leave an indelible mark on the battlefield with raw, uncompromising force. Win and revel in absolute domination. Lose and use the brief energy low as a precursor to an explosive comeback.
            Atomic is a classic aggro style with great tempo. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/atomic/atomic_faction1.jpg`} alt="Atomic Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/atomic/atomic_faction1.jpg`} alt="Atomic Magic" />
        <div>
          <p>
          Dive deeper and transform into an Atomic Warlock, an awe-inspiring figure clad in armor constructed of dense, energy-absorbing alloys. As an Atomic Warlock, you become a walking power plant, your eyes ablaze with radioactive glow. 
          This path is well-suited to those from backgrounds in nuclear physics and material science, military and engineering. 
          Power station workers, radiation therapists, and firefighters, those who've tasted the life-saving and destructive potential of energy, make excellent candidates for the Atomic Path as well.
          <br />
          For Atomic Mages, energy and matter are freely convertible, utilized with flawless efficiency. They envision an ideal universe powered by the tamed and harnessed destructive potential of atomic energy, fueling boundless innovation and growth. They are the power generators and artificers of society, constituting the heart of infrastructure, safeguarding their protectorates with robust armaments and energy fields.
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/atomic/atomic_faction2.jpg`} alt="Atomic Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/atomic/atomic_faction2.jpg`} alt="Atomic Magic" />
        <div>
          <p>
          Atomic-Mages are always electrified with ambition. The only things they embrace more than efficiency are hardship and war. To them, the universe rightfully belongs to those who make the best use of it. War is their method of choice to achieve their imperialistic goals.
          Experience the raw power of nuclear forces with the Atomic deck, tailored for players who revel in high-risk, high-reward gameplay. The design of the deck boasts an assortment of radioactive mutants and explosive reactions, appealing to anyone who values a visually stunning displays of power. 
          Become a part of the Atomic Magic realm, immerse yourself in the thrill of battle, and let the rush of victory course through your veins.         
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/create" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Atomic;

