// Ensure the environment variables are loaded in non-production environments
if (process.env.NODE_ENV !== 'production') {
  require('dotenv').config({ path: '.env.local' });
}

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH,
  projectId: process.env.REACT_APP_FB_PROJECT,
  storageBucket: process.env.REACT_APP_FB_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MSGID,
  appId: process.env.REACT_APP_FB_ID,
  measurementId: process.env.REACT_APP_FB_ANAL
};

// Check if all required config values are present
const requiredConfigValues = [
  'apiKey', 'authDomain', 'projectId', 'storageBucket', 'messagingSenderId', 'appId', 'measurementId'
];
const missingConfigValues = requiredConfigValues.filter(key => !firebaseConfig[key]);
if (missingConfigValues.length) {
  console.error(`Missing Firebase config values: ${missingConfigValues.join(', ')}`);
  throw new Error(`Missing Firebase config values: ${missingConfigValues.join(', ')}`);
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
let analytics;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

// Initialize services
const auth = getAuth();

const db = getFirestore(app);

export { app, auth, db, analytics };
